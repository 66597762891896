.notification-badge {
  .mat-badge-content {
    background-color: var(--w-error-color);
    color: var(--w-error-contrast-font-color);
  }
}

.alert-badge {
  .alert-critical {
    .mat-badge-content {
      background: var(--w-alert-critical-bg-color);
      color: var(--w-alert-critical-font-color);
    }
  }

  .alert-high {
    .mat-badge-content {
      background: var(--w-alert-high-bg-color);
      color: var(--w-alert-high-font-color);
    }
  }

  .alert-medium {
    .mat-badge-content {
      background: var(--w-alert-medium-bg-color);
      color: var(--w-alert-medium-font-color);
    }
  }

  .alert-low {
    .mat-badge-content {
      background: var(--w-alert-low-bg-color);
      color: var(--w-alert-low-font-color);
    }
  }
}
