@use 'wlm-theme' as *;

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: var(--w-primary-color) !important;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--w-primary-color-contrast-font-color) !important;
  --mdc-protected-button-container-color: var(--w-primary-color) !important;
}

.mat-mdc-button-base {
  font-size: $wtr-button-font-size !important;

  &:not(.mat-mdc-icon-button) {
    padding: $wtr-main-space * 2 !important;
  }
}

@mixin wtr-button-disabled($disabled-color) {
  &:disabled,
  &[disabled],
  &.mat-button-disabled {
    color: $disabled-color !important;
  }
}

@mixin wtr-button-disabled-background($disabled-color) {
  &:disabled,
  &[disabled],
  &.mat-button-disabled {
    background-color: $disabled-color !important;
  }
}

@mixin wtr-button-disabled-background-regular($disabled-color) {
  &:disabled,
  &[disabled],
  &.mat-button-disabled {
    color: $disabled-color !important;
    border: 1px solid $disabled-color !important;
  }
}

@mixin wtr-no-icon-button-styles($color, $disabled-color) {
  &:not(.mat-mdc-icon-button) {
    color: $color;

    @include wtr-button-disabled($disabled-color);
  }
}

@mixin wtr-icon-button-styles($color, $disabled-color) {
  &.mat-mdc-icon-button {
    .mat-icon {
      color: $color;
    }

    &:disabled,
    &[disabled],
    &.mat-button-disabled {
      mat-icon {
        color: $disabled-color;
      }
    }
  }
}

.vertical-align-buttons {
  mat-icon {
    vertical-align: middle;
  }
}

.mat-button-toggle-checked {
  .mat-button-toggle-label-content {
    color: var(--w-primary-color-contrast-font-color);
  }
}

.btn-size-small {
  line-height: 24px !important;
}

button {
  // By default, icons are primary color but the rest of the buttons are regular.
  @include wtr-no-icon-button-styles(
    var(--w-button-font-color),
    var(--w-button-font-color-disabled)
  );
  @include wtr-icon-button-styles(var(--w-primary-color), var(--w-primary-color-disabled));
}

.wtr-button-primary {
  @include wtr-no-icon-button-styles(var(--w-primary-color), var(--w-primary-color-disabled));
  @include wtr-icon-button-styles(var(--w-primary-color), var(--w-primary-color-disabled));
}

.wtr-button-regular {
  @include wtr-no-icon-button-styles(
    var(--w-button-font-color),
    var(--w-button-font-color-disabled)
  );
  @include wtr-icon-button-styles(var(--w-button-font-color), var(--w-button-font-color-disabled));
}

/***********************************/
// These are non-icon type buttons
/***********************************/
@mixin wtr-action-button-styles($back-color, $font-color) {
  background-color: $back-color !important;
  font-family: $wtr-action-button-font-family !important;
  font-weight: 900 !important;
  color: $font-color !important;
}

.wtr-action-button-primary {
  @include wtr-action-button-styles(
    var(--w-primary-color),
    var(--w-primary-color-contrast-font-color)
  );
  @include wtr-button-disabled-background(var(--w-primary-color-disabled));
}

.wtr-action-button-regular {
  @include wtr-action-button-styles(
    var(--w-button-regular-bg-color),
    var(--w-button-regular-font-color)
  );
  border: 1px solid var(--w-button-regular-font-color) !important;
  @include wtr-button-disabled-background-regular(var(--w-button-font-color-disabled));
}

.wtr-action-button-error {
  @include wtr-action-button-styles(var(--w-error-color), var(--w-error-contrast-font-color));
  @include wtr-button-disabled-background(var(--w-error-color-disabled));
}

.wtr-action-button-primary-small {
  @include wtr-action-button-styles(
    var(--w-primary-color),
    var(--w-primary-color-contrast-font-color)
  );
  @include wtr-button-disabled-background(var(--w-primary-color-disabled));
}

.center-icon-button {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.wtr-override-button-overlay {
  .mat-button-focus-overlay {
    background: transparent !important;
  }
}

button.mat-mdc-menu-item {
  .mdc-list-item__primary-text {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mat-mdc-button-persistent-ripple {
  display: none !important;
}

.collapsible-button {
  color: unset !important;
  background-color: unset !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
}

.menu-with-buttons {
  .mdc-list,
  .mat-mdc-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
