.campaign-wizard {
  height: 100%;

  div.mat-horizontal-content-container {
    height: calc(100% - 100px);
  }

  div.mat-horizontal-stepper-content[style*='inherit'] {
    height: 100%;
  }

  div.mat-horizontal-stepper-wrapper {
    height: 100%;
  }
}
