@use 'wlm-theme' as *;

@mixin wtr-swal2-toast-text($font-size) {
  font-family: $wlm-font-family !important;
  font-size: $font-size !important;
}

.swal2-toast-title {
  @include wtr-swal2-toast-text($wlm-font-size);

  line-height: 20px !important;
  margin: 0.5em 1em 0 1em !important;
}

div:where(.swal2-container.swal2-custom-container) {
  div:where(.swal2-popup) {
    padding-bottom: $wtr-main-space * 2 !important;
  }

  h2:where(.swal2-title) {
    padding: $wtr-main-space * 2 !important;
    padding-bottom: 0px !important;
  }

  div:where(.swal2-actions) {
    margin-top: $wtr-main-space * 2 !important;
  }

  button:where(.swal2-styled) {
    margin: 0px 4px !important;
  }
}

div:where(.swal2-icon) {
  border: 0px !important;
  margin-top: 0px !important;
}

.swal2-container {
  right: $wtr-main-space * 3 !important;
}

.swal2-toast-text {
  @include wtr-swal2-toast-text($wlm-subtitle-font-size);
}

.swal2-toast-icon {
  border: none !important;
  height: $wtr-icon-toast-size;
  width: $wtr-icon-toast-size;
}

.swal2-custom-icon {
  width: $wtr-icon-swal-size;
  height: $wtr-icon-swal-size;
  margin: $wtr-main-space * 2 auto 0px;
  border: 0px;
}

.swal2-custom-icon-success {
  -webkit-mask-image: url(/assets/icons/wtr-icons/success.svg);
  mask-image: url(/assets/icons/wtr-icons/success.svg);
  background-color: var(--w-swal-success-icon-color);
}

.swal2-custom-icon-error {
  -webkit-mask-image: url(/assets/icons/wtr-icons/report-error.svg);
  mask-image: url(/assets/icons/wtr-icons/report-error.svg);
  background-color: var(--w-swal-error-icon-color);
}

.swal2-custom-icon-warning {
  -webkit-mask-image: url(/assets/icons/wtr-icons/warning.svg);
  mask-image: url(/assets/icons/wtr-icons/warning.svg);
  background-color: var(--w-swal-warning-icon-color);
}

.swal2-custom-icon-info {
  -webkit-mask-image: url(/assets/icons/wtr-icons/info.svg);
  mask-image: url(/assets/icons/wtr-icons/info.svg);
  background-color: var(--w-swal-info-icon-color);
}

.swal2-toast-popup {
  color: var(--w-swal-font-color) !important;
  top: 10%;
}

.swal2-toast-popup-success {
  background-color: var(--w-swal-bg-success-color) !important;
}

.swal2-toast-popup-error {
  background-color: var(--w-swal-bg-error-color) !important;
}

.swal2-toast-popup-warning {
  background-color: var(--w-swal-bg-warning-color) !important;
}

.swal2-toast-popup-info {
  background-color: var(--w-swal-bg-info-color) !important;
}
