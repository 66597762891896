@use 'wlm-theme' as *;

input.k-checkbox {
  box-sizing: border-box;
  height: $wtr-checkbox-size;
  width: $wtr-checkbox-size;
  border: 1px solid var(--w-checkbox-border-color) !important;
  border-radius: 3px;
}

.mat-mdc-checkbox {
  .mdc-checkbox__ripple {
    display: none;
  }
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: var(--w-primary-color-contrast-font-color) !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__mixedmark {
  border-color: var(--w-primary-color-contrast-font-color) !important;
}

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: #{$wtr-checkbox-size};

  .mdc-checkbox__background {
    width: $wtr-checkbox-size;
    height: $wtr-checkbox-size;
  }

  label {
    font-size: $wtr-input-font-size;
    padding-top: 4px;
    padding-left: $wtr-main-space !important;
  }
}

.wtr-checkbox-lg {
  .mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: $wtr-checkbox-size-lg;

    input[type='checkbox'],
    .mdc-checkbox__background {
      width: $wtr-checkbox-size-lg;
      height: $wtr-checkbox-size-lg;
    }

    label {
      font-size: $wtr-input-font-size;
      padding-top: $wtr-main-space;
    }
  }
}

mat-checkbox.custom-checkbox .mat-checkbox-inner-container {
  width: $wtr-checkbox-size;
  height: $wtr-checkbox-size;
}

mat-checkbox.custom-checkbox label {
  padding-left: $wtr-main-space * 2;
}

mat-checkbox.custom-checkbox .mat-checkbox-frame {
  border-color: var(--w-primary-color);
  border-width: 1px;
}
