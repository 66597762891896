@use '../../aawl-theme/basic/wtr-variables.scss' as *;

.map-search-box {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    border-radius: 5px !important;
  }
}

.map-popup {
  position: fixed !important;
  z-index: 9;
}

.map-resize {
  .mapboxgl-canvas-container {
    display: flex !important;
    flex: 1 1 100% !important;
  }

  .mapboxgl-canvas {
    display: flex !important;
    flex: 1 1 100% !important;
  }
}

.mapboxgl-popup {
  top: unset;
  left: unset;
}

.mapboxgl-canvas {
  position: unset;
  top: unset;
  left: unset;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  pointer-events: auto;
  position: unset;
  background: var(--w-component-bg-color);
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.mapboxgl-ctrl-bottom-right {
  right: 0;
  bottom: $wtr-main-space * 2;
}

.map-custom-icon .mat-icon svg {
  fill: var(--w-map-time-bar-font-color);
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas,
.mapboxgl-canvas-container.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-drag-pan .mapboxgl-canvas,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate .mapboxgl-canvas {
  touch-action: unset;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
  cursor: auto;
  user-select: unset;
}

.nested-drag-container {
  .items-container {
    border: 0px !important;
    border-radius: 0px !important;
    min-height: 0px !important;
  }

  .item-box-border {
    border-color: var(--w-main-border-color) !important;
    border-bottom: 0px !important;
  }

  .drag-icon,
  .drag-icon:hover {
    color: var(--w-map-drag-icon-color);
  }
}

.layer-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.map-tab-panel-item {
  .mat-mdc-tab-body-content {
    overflow: unset;
  }
}

.custom-map-filters {
  mat-slide-toggle {
    .mdc-form-field {
      width: 100%;

      button {
        margin-left: auto;
      }

      label {
        margin-left: 0;
      }
    }
  }

  mat-radio-button {
    .mdc-form-field {
      width: 100%;

      .mdc-radio {
        margin-left: auto;
        margin-right: 4px;
      }

      label {
        margin-left: 0;
      }
    }
  }

  mat-checkbox .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;
  }

  mat-checkbox .mat-checkbox-frame {
    border-color: #1e6ab1;
    border-width: 1px;
  }

  mat-checkbox .mat-checkbox-layout {
    width: 100%;
  }

  mat-checkbox .mat-checkbox-label {
    flex: auto;
  }

  mat-dialog-content.mat-dialog-content {
    display: inline;
  }
}

.custom-map-search {
  mat-form-field {
    > .mat-form-field-wrapper,
    > .mat-mdc-text-field-wrapper {
      padding-bottom: 0 !important;
      background-color: var(--w-component-bg-color);
    }
  }

  .mat-option-text span {
    vertical-align: middle !important;
  }

  .mat-form-field-flex {
    > .mat-form-field-infix,
    > .mat-mdc-form-field-infix {
      padding: 0.45em 0 0.65em 0 !important;
      font-size: 14px;
    }
  }

  .mat-form-field-label-wrapper {
    top: -1em;
  }
}

.mapboxgl-popup {
  background-color: var(--w-component-bg-color);
  color: var(--w-main-font-color);
}

.map-tab-panel {
  .mat-mdc-dialog-content {
    max-height: 100%;
  }
}

.map-search-option,
.map-search-option .mdc-list-item__primary-text {
  display: flex !important;
  align-items: center !important;
}

.map-floating-button {
  color: var(--w-primary-color);
  background-color: var(--w-map-time-bar-font-color);
  margin-bottom: $wtr-main-space;
  margin-top: 4px;
  border-radius: $wtr-fields-border-radius;

  &:hover {
    color: var(--w-primary-color);
  }

  &.active {
    color: var(--w-main-bg-color);
    background-color: var(--w-primary-color);
    .mat-icon {
      color: var(--w-main-bg-color);
    }
  }
}

.layer-item-inner {
  border-bottom: 1px solid var(--w-map-layer-item-color);
  padding-bottom: 6px; // Was only in thin client branch #24459
}

.layer-item-inner-last {
  border-bottom: 0px !important;
  padding-bottom: 0px;
}

/* Was only in thin client branch */
.map-tab-panel-item {
  .mat-mdc-tab-body-content {
    overflow: unset;
  }
}

.map-tooltip-paginator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $wtr-main-space;
  margin-left: $wtr-main-space * 2;

  .mdc-icon-button {
    width: $wtr-main-space * 3 !important;
  }
}
