@use 'wlm-theme' as *;

.leaflet-popup-content {
  width: auto !important;
  height: auto !important;
}

.leaflet-popup-tip-container {
  left: 15%;
}

.leaflet-image-layer {
  visibility: inherit !important;
}

.leaflet-grab {
  cursor: default;
}

.leaflet-sidebar {
  z-index: 1000 !important;
  margin-bottom: 65px;
}

.leaflet-sidebar-header {
  background-color: unset;
  color: unset;
}

.side-panel-icon {
  mat-icon {
    padding-top: $wtr-main-space;
  }
}

.leaflet-pane {
  z-index: 10;
}

.leaflet-popup-pane {
  z-index: 9999999;
}

.leaflet-control-container {
  .leaflet-bottom {
    width: calc(100% - 16px);
  }
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: $wtr-fields-border-radius;
  border-top-right-radius: $wtr-fields-border-radius;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: $wtr-fields-border-radius;
  border-bottom-right-radius: $wtr-fields-border-radius;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  color: var(--w-primary-color);
}

.leaflet-right .leaflet-control {
  margin-right: $wtr-main-space;
}

.leaflet-right .leaflet-control:last-child {
  margin-bottom: 0px;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-sidebar-close {
  top: 6px;
  right: 6px;
  svg {
    width: $wtr-main-space * 2;
    height: $wtr-main-space * 2;
  }
}

.leaflet-sidebar-content {
  background-color: var(--w-component-bg-color);
}

.leaflet-sidebar-pane {
  padding: $wtr-main-space * 2;

  &#topology,
  &#thematic {
    padding-bottom: $wtr-main-space;
  }
}

.leaflet-sidebar-content,
.leaflet-sidebar-pane {
  min-width: 0px !important;
}
