@use 'wlm-theme' as *;

.mat-mdc-form-field-type-mat-chip-grid {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      width: min-content;

      .mat-mdc-chip-set,
      .mat-mdc-chip-grid {
        width: min-content;
      }

      input.mat-mdc-input-element {
        width: min-content;
      }
    }
  }
}

.custom-chip-inline {
  &.mat-mdc-form-field-type-mat-chip-grid {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        width: 100%;

        .mat-mdc-chip-set,
        .mat-mdc-chip-grid {
          width: 100%;
        }

        input.mat-mdc-input-element {
          width: 100%;
        }
      }
    }
  }
}

.mat-mdc-chip {
  margin-right: 4px;
}

.mdc-evolution-chip__text-label,
.mat-mdc-chip-action-label {
  display: contents;
  font-size: $wtr-input-font-size;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  padding-left: $wtr-main-space !important;
  // padding-right: 0px !important;
}

.mdc-evolution-chip-set__chips {
  flex-flow: wrap !important;

  .mat-mdc-chip-input {
    flex-basis: 120px;
  }
}

.k-grid-filter-popup {
  .mdc-evolution-chip-set__chips {
    max-width: 500px;
    overflow-y: auto;
  }
}

.invalid-chip {
  background-color: var(--w-error-color) !important;
  span {
    color: var(--w-error-contrast-font-color) !important;
  }
}
