@use './wtr-variables.scss' as *;

@mixin wtr-base-header {
  letter-spacing: $wtr-main-letter-spacing;
}

// Headline = h1
@mixin wtr-headline {
  @include wtr-base-header;
  font-family: $wtr-headline-font-family;
  font-size: $wtr-headline-font-size;
  color: var(--w-headline-font-color);
}

// Title = h2
@mixin wtr-title {
  @include wtr-base-header;
  font-family: $wtr-title-font-family;
  font-size: $wtr-title-font-size;
  color: var(--w-title-font-color);
}

// Subheading = h3
@mixin wtr-subheading {
  @include wtr-base-header;
  font-family: $wtr-subheading-font-family;
  font-size: $wtr-subheading-font-size;
  line-height: $wtr-subheading-font-size;
  color: var(--w-subheading-font-color);
}

@mixin wtr-size-rules($size) {
  min-width: $size;
  min-height: $size;
  width: $size;
  height: $size;
}
