/* You can add global styles to this file, and also import other style files */
@use './../wlm-theme.scss' as *;
@use './wlm-material-theme.scss' as *;
@use './wlm-kendo-theme.scss' as *;
//@import './wlm-gl-theme_v2.scss';

@font-face {
  font-family: Barlow;
  src: url(/assets/fonts/Barlow-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Barlow;
  src: url(/assets/fonts/Barlow-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: Barlow;
  src: url(/assets/fonts/Barlow-Light.ttf) format('truetype');
  font-weight: 300;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(/assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(/assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.main-body {
  // This overflow hidden was added to handle the overflows in GL when we drag
  // an element and the dragged element preview causes the body to overflow.
  overflow: hidden;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.icon-outlined {
  font-family: 'Material Icons Outlined';
}

.top-container {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  padding-left: 0.5rem;
  padding-top: 0.25em;
}

hr.separator {
  border: 0;
  border-bottom: 1px solid var(--w-separator-color);
  width: calc(100% - 20px);
  margin: auto;
}

hr.basic-separator {
  border: 0;
  border-bottom: 1px solid var(--w-separator-color);

  &.vertical {
    -webkit-transform: rotate(90deg);
  }
}

#center-page {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 100%;

  .filter-header {
    height: auto !important;
    padding-block-start: 0.75rem;
    padding-block-end: 1rem;
  }

  #center-container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.6em;
    flex: 1 1 100%;

    .filter-expander {
      margin-block-end: 0.5em;
    }

    .generic-grid {
      flex: 1;
      min-height: 0;
    }

    .generic-grid-no-filter {
      height: 100%;
      max-height: 100%;
    }
  }
}

#rpanel .filter-buttons-container,
#center-page .filter-buttons-container {
  justify-content: flex-end;
  display: flex;

  button {
    margin: 0 0.25rem;
  }
}

// center page and right panel needs different padding to be aligned properly
#rpanel .filter-buttons-container {
  padding: 0.5rem 0.075rem;
}

#center-page .filter-buttons-container {
  padding: 0.5rem 0.5rem 0 0;
  flex-grow: 1;
}

#rpanel-content {
  overflow: auto;
}

mat-icon.flip svg {
  transform: scale(-1, 1);
}

mat-label.filter-title {
  font-size: 1.1rem;
  // font-family: $header-font;
  letter-spacing: 0.01em;
  color: var(--w-filter-field-label-color);
}

///////// MAPBOX

.mapboxgl-popup {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  z-index: 9;
  background-color: #fff;
  position: absolute;
  padding: 10px;
  filter: drop-shadow(2px 4px 10px hsla(0deg, 0%, 0%, 0.2));
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: transparent;
}

.mapboxgl-marker {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  opacity: 1;
  transition: opacity 0.2s;
}

/**
* Disables the size constratins of a mat-menu. Can be used as <mat-menu class="panel-disable-fixed" >, as mat-menu proxies classes to the panel.
*/
.panel-disable-fixed {
  min-width: none !important;
  max-width: none !important;
  min-height: none !important;
  max-height: none !important;
  margin-top: -15px;
}

/**
* Used by the HighlightSearchPipe.
*/
.highlight-search {
  text-decoration: underline;
  font-weight: bold;
}

.input-error {
  $input-error-color: #c62828;

  .mat-form-field-underline {
    /*change color of underline*/
    background-color: $input-error-color !important;
  }

  .mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: $input-error-color !important;
  }
}

.default-spinner-location {
  display: flex;
  align-items: center;
  color: var(--w-primary-color);
  margin-top: 20%;
  margin-bottom: 20%;
}

.no-data-label {
  text-align: center;
  color: #777;
}

html {
  font-size: $wlm-font-size;
  font-family: $wlm-font-family;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

body,
html {
  height: 100%;
  margin: 0;
}
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

img.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Material

// Add only the overrides for the elements that you don't have the control.
// Those are the DOM elements auto generated by any components.

// app-module-link

app-module-link .mat-expansion-panel-body {
  padding: 0;
}

.mat-menu-panel.account-card-menu {
  max-width: 25rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

h1 {
  font-family: $header-font;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.015em;
  font-weight: 300;
}

h2 {
  font-family: $header-font;
  font-size: 36px;
  letter-spacing: -0.005em;
  line-height: 40px;
  font-weight: 300;
}

h3 {
  font-family: $header-font;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.01em;
  padding-top: 16px;
  padding-bottom: 4px;
  margin: 0;
}

h4 {
  font-family: $header-font;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
  padding-top: 16px;
  padding-bottom: 4px;
  margin: 0;
}

pre {
  font-size: 1rem;
  font-family: $wlm-font-family;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

*:focus,
*:active:focus,
*.active:focus {
  outline: none;
  outline-style: none;
}

p,
textarea,
.chip-item,
label {
  font-family: $wlm-font-family !important;
  /* Add !important to overwrite all elements */
}

.mat-tooltip {
  font-size: $wlm-font-size;
}

.kendo-tooltip * {
  font-size: $wlm-font-size;
  font-family: $wlm-font-family !important;
}

.nav-dropdown {
  height: 100%;
}

wlm-tab-detail-panel {
  background: var(--w-component-bg-color);
}

.remove-side-margins {
  margin-left: 0px;
  margin-right: 0px;
}
.spinner-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(-50% - 30px);
  position: absolute;
  background-color: rgba(240, 240, 240, 0.5);
  z-index: 10000;
  color: var(--w-primary-color);
}
.large-tooltip {
  max-width: unset !important;
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-between;
}

.sweet-snackbar-title {
  font-family: $wlm-font-family !important;
  font-size: $wlm-font-size !important;
  line-height: 20px !important;
}

.sweet-message-title {
  font-family: $wlm-font-family !important;
  font-weight: unset !important;
  font-size: $wlm-font-size !important ;
  line-height: 20px !important;
}

.sweet-dialog-title {
  font-family: $wlm-font-family !important;
  font-weight: unset !important;
  font-size: $wlm-font-size !important ;
  line-height: 20px !important;
  display: flex !important;
  align-items: flex-end;
  justify-content: center !important;
}

.sweet-dialog-buttons {
  background-color: var(--w-primary-color) !important;
}

.sweet-dialog-buttons:focus {
  box-shadow: 0 0 0 3px w-color-alpha(var(--w-primary-color), 0.5) !important;
}

.sweet-dialog-popup {
  padding-top: 10px !important;
  min-height: 150px;
}

button.custom-icon-button {
  color: var(--w-primary-color);
  margin: 0 0.25rem;
  padding: 0 0.25rem;

  mat-icon svg path {
    fill: var(--w-primary-color);
  }
}

button.custom-icon-button[disabled] {
  color: var(--w-button-font-color-disabled);

  mat-icon svg path {
    fill: var(--w-button-font-color-disabled);
  }
}

button.datepicker-icon-button {
  mat-icon {
    // color: rgba(0, 0, 0, 0.54); #TODO:23097
  }
}

.highlight-text {
  color: var(--w-primary-color) !important;
}

.title-separator {
  width: 100%;
  text-align: center;
  margin-left: 0;
}

.kendo-grid-no-overflow .k-grid-content {
  overflow-y: unset;
}

.ql-mention-list-item {
  font-size: $wlm-font-size !important ;
  line-height: 39px !important;
}

.content-stretch {
  width: 100%;
  height: 100%;
}

.mat-radio-button .mat-radio-ripple {
  height: 30px !important;
  width: 30px !important;
  left: calc(50% - 15px) !important;
  top: calc(50% - 15px) !important;
}

.select-all-width {
  width: 17px;
}

.ne-component-title {
  color: #717572;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.top-title-position {
  margin-top: 24px;
  margin-bottom: 8px;
}

.full-size {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
}

.full-size-historical-switcher {
  display: flex;
  flex: 1 1 100%;
  height: calc(100% - 30px);
  width: 100%;
}

.nested-dynamic-layout {
  .lm_content {
    overflow: hidden;
  }
}

.edition-mark {
  height: 5px;
  width: 5px;
  background-color: var(--w-primary-color);
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
}

.empty-legend {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.cell-edit-input {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    padding: 0.5em 0 0.5em 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-label {
    margin-top: -15px;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-infix,
    .mat-mdc-form-field-infix {
      padding: 0.5em;
    }

    label.ng-star-inserted {
      transform: translateY(-0.59375em) scale(0.5) !important;
    }

    .mat-form-field-wrapper,
    .mat-mdc-text-field-wrapper {
      margin: 0.25em 0;
      padding-bottom: 0;
    }
  }

  .mat-form-field-subscript-wrapper {
    display: none;
  }

  font-size: $wlm-font-size !important;
}

.small-mat-icon-button {
  .mat-button-focus-overlay {
    background-color: transparent !important;
  }

  *:focus {
    outline: 0 !important;
  }
}

wlm-generic-grid {
  kendo-popup {
    position: fixed !important;
    z-index: 200 !important;
  }
}

wlm-local-grid {
  kendo-popup {
    position: fixed !important;
    z-index: 200 !important;
  }
}

.cross-site-errors-popup {
  width: 50% !important;
}

.numerictextbox-style {
  font-size: $wlm-font-size !important;
}

.grid-header-template {
  font-size: $header-font-size;
}

.bi-widget {
  .selector-node {
    font-size: $wlm-font-size !important;
  }
}

wlm-bi-save-popup {
  .dialog-content {
    overflow-y: hidden;
  }
}

.customer-details-container {
  .lm_content {
    width: inherit !important;
    overflow-y: hidden;
  }
  .customer-details-meters-layout {
    .lm_content {
      width: inherit !important;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
