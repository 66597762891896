@use 'wlm-theme' as *;

$wtr-treeview-item-border: 1px solid var(--w-main-border-color);

.k-treeview {
  color: inherit;
  .k-treeview-leaf {
    &.k-selected {
      color: var(--w-tree-leaf-selected-color);
      background-color: var(--w-tree-leaf-selected-bg-color);
    }
  }
}

.selector-treeview {
  &.k-treeview {
    .k-treeview-leaf {
      width: 100%;
      padding-block: 0px;
      padding-inline: 0px;
      background-color: var(--w-selector-tree-leaf-bg-color);

      &:hover {
        background-image: none;
        background-color: var(--w-selector-tree-leaf-hover-bg-color);
        border: none;
      }

      &.k-selected {
        background-image: none;
        background-color: var(--w-selector-tree-leaf-selected-bg-color);
        color: var(--w-selector-tree-leaf-selected-color);
        border: none;
      }

      .k-treeview-leaf-text {
        width: 100%;
      }
    }
  }
}

.filter-item-treeview {
  &.k-treeview {
    .k-treeview-leaf {
      padding-block: 2px;
      padding-inline: 8px;
      border-radius: 15px;

      .k-treeview-leaf-text {
        margin: 0;
        border: 0px solid transparent;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        align-content: center;
        vertical-align: middle;
        position: relative;
      }
    }
  }
}

.custom-treeview-selector {
  [kendotreeviewgroup] {
    border-top: $wtr-treeview-item-border;
  }

  [kendotreeviewitem] {
    // This selector aims to add an upper shadow to the element that is inmediately down of the expanded element.
    &[aria-expanded='true'] + [kendotreeviewitem] {
      box-shadow: 0px -3px 1px -2px rgb(0 0 0 / 20%), 0px -2px 2px 0px rgb(0 0 0 / 14%),
        0px -1px 5px 0px rgb(0 0 0 / 12%) !important;
    }
  }

  [kendotreeviewitemcontent] {
    border-radius: 0px !important;

    .selector-node {
      &:not(.node-expanded) {
        border-bottom: $wtr-treeview-item-border;
      }

      &.node-expanded {
        border-bottom: none !important;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
          0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
      }
    }
  }

  .normal-node {
    cursor: pointer;
    padding: $wtr-main-space !important;
    padding-left: $wtr-main-space * 2 !important;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $wtr-treeview-item-height;
  }

  .leaf-node {
    padding-left: $wtr-main-space * 2;
    background-color: var(--w-list-item-bg-color);
  }

  .k-treeview-item,
  .k-treeview .k-in {
    padding: 0px !important;
  }
}
