@use '@angular/material' as *;

$wlm-font-size: 14px;
$wlm-subtitle-font-size: 1em;
$wlm-font-family: Roboto, sans-serif;
$wlm-error-font-size: 10.5px;
$wlm-collapsible-title-font-size: 14px;

$wlm-warn: define-palette($red-palette, 800);

$standard-px: 16px;
$standard-base-px: 8px;

$header-font: Barlow;
// Apply specific heigth to it aligns with the content in the pages.
$sidebar-widget-height: 96.4%;

$select-option-height: 39px;
//drag lists
$drag-border-radius: 10px;

$ne-config-container-margin: 0 16px 24px 24px;

$map-legend-min-width: 400px;
$map-legend-height: 55px;

// empty legend
$empty-legend-height: 80px;
