@use './wtr-variables.scss' as *;
@use './wtr-native-elements.scss' as *;
@use './wtr-mixins.scss' as *;
@use './wtr-fonts.scss' as *;

@use './wtr-material-setup.scss' as *;

.wtr-headline {
  @include wtr-headline;
}

.wtr-title {
  @include wtr-title;
}

.wtr-subheading {
  @include wtr-subheading;
}

/**
 * These *-bg classes can be referred in code by the StylesService.
 */

.wtr-primary-color-bg {
  background-color: var(--w-primary-color);
}

.wtr-primary-color {
  color: var(--w-primary-color);
}

.wtr-primary-color-90-bg {
  background-color: var(--w-primary-color-900);
}

.wtr-primary-color-80-bg {
  background-color: var(--w-primary-color-800);
}

.wtr-primary-color-70-bg {
  background-color: var(--w-primary-color-700);
}

.wtr-primary-color-60-bg {
  background-color: var(--w-primary-color-600);
}

.wtr-primary-color-50-bg {
  background-color: var(--w-primary-color-500);
}

.wtr-primary-color-40-bg {
  background-color: var(--w-primary-color-400);
}

.wtr-primary-color-30-bg {
  background-color: var(--w-primary-color-300);
}

.wtr-primary-color-20-bg {
  background-color: var(--w-primary-color-200);
}

.wtr-primary-color-10-bg {
  background-color: var(--w-primary-color-100);
}

.wtr-error-color-bg {
  background-color: var(--w-error-color);
}

.wtr-error-color {
  color: var(--w-error-color);
}

.wtr-warning-color-bg {
  background-color: var(--w-warning-color);
}

.wtr-success-color-bg {
  background-color: var(--w-success-color);
}

.wtr-success-color {
  color: var(--w-success-color);
}

.wtr-neutral-color-black-2-color-bg {
  background-color: var(--w-neutral-color-black-2);
}

.wtr-neutral-color-black-2-color {
  color: var(--w-neutral-color-black-2);
}

.wtr-neutral-color-white-color-bg {
  background-color: var(--w-neutral-color-white);
}

.wtr-neutral-color-white-color {
  color: var(--w-neutral-color-white);
}

.wtr-nav-bar-icon-color {
  color: var(--w-nav-bar-icon-color);
}

wlm-uom-configuration {
  .uom-config-container {
    display: flex;
    align-items: stretch;
    margin: 0 $wtr-main-space * 2 $wtr-main-space * 3 $wtr-main-space * 3;
    height: 100%;
    overflow: hidden;
    min-height: 60%;
  }

  .uom-component-title {
    font-size: $wtr-main-space * 2;
    font-weight: $wtr-font-weight-100 * 6;
  }

  .uom-group {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
  }

  .uom-group-configuration {
    border-radius: $wtr-space-4;
    border: $wtr-space-1 solid var(--w-primary-color);
    background-color: var(--w-component-bg-color);
    margin: $wtr-main-space;
    height: 100%;
    overflow-y: auto;
  }

  .uom-unit-select {
    width: 42%;
  }

  .top-title-position {
    margin: $wtr-main-space * 3 0 $wtr-main-space $wtr-main-space;
  }

  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }
}

wlm-logs-system-message {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    height: 100%;
  }

  .mat-form-field-flex {
    height: 100%;
  }

  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    height: -webkit-fill-available;
  }
}

wlm-data-validation-value-ranges {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }
}

wlm-data-validation-chart {
  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper,
    .mat-mdc-text-field-wrapper {
      margin: 0.75em 0 !important;
    }
  }
}

.chart-map-sync {
  .mapboxgl-canvas,
  .mapboxgl-map,
  .mapboxgl-canvas-container,
  .mapboxgl-canvas {
    height: inherit !important;
    width: inherit !important;
  }
}

.schematics-main-layout {
  .lm_content {
    height: inherit !important;
    width: inherit !important;
  }
}
