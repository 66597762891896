@use 'wlm-theme' as *;

.k-dropdownlist .k-input-inner .k-input-value-text,
.k-dropdowntree .k-input-inner .k-input-value-text,
.k-textarea,
.k-textbox {
  font-size: $wtr-main-font-size !important;
}

.k-dropdownlist .k-input-inner,
.k-dropdowntree .k-input-inner {
  border-color: var(--w-main-border-color);
}

.k-dropdownlist .k-input-inner.k-state-hover,
.k-dropdownlist .k-input-inner.k-state-focused,
.k-dropdowntree .k-input-inner:hover,
.k-dropdowntree .k-input-inner.k-state-hover,
.k-dropdowntree .k-input-inner.k-state-focused {
  border-top: 0px;
  border-color: var(--w-primary-color) !important;
}
