@use '@angular/material' as mat;
@use './../wlm-theme.scss' as *;

//Mat-grid-list component style
.mat-figure,
mat-label {
  font-size: 1rem;
  font-family: $wlm-font-family;
}

//Mat-chips
.mat-chip-list-wrapper {
  margin: -1px !important;
}
.mat-chip-list-wrapper input.mat-input-element,
.mat-chip-list-wrapper .mat-standard-chip {
  margin: 1px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0 !important;
  background-color: var(--w-primary-color) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--w-primary-color) !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--w-button-font-color-disabled) !important;
}

.mat-expansion-panel {
  border-radius: 0 !important;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: w-color-alpha(var(--w-menu-item-selected-color), 0.15) !important;
  /*
  background: linear-gradient(
    0deg,
    w-color-alpha(var(--w-menu-item-selected-color), 0.13) 0%,
    w-color-alpha(var(--w-menu-item-selected-color), 0.16) 100%
  ) !important;
  */
}

.mat-button,
.mat-raised-button,
.mat-mdc-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-chip {
  font-size: $wlm-font-size;
}

.mat-field {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0px !important;
  }

  .mat-form-field {
    width: 100%;
  }
}

.mf-small {
  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    padding: 0.4em 0px !important;
  }
  .mat-form-field-label-wrapper {
    top: -1.5em;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float,
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float,
  .mat-form-field-appearance-standard.mat-form-field-can-float.mat-form-field-should-float,
  .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
  }
}

.mf-padding-small {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    // padding-bottom: 0.5em;
  }
}

.mf-small-infix {
  .mat-form-field-appearance-outline {
    .mat-form-field-infix,
    .mat-mdc-form-field-infix {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .mat-form-field-label-wrapper {
    top: -1.2em; // Required by the theme
  }
}

.mf-reset-width {
  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    // Remove the 180px fixed width.
    width: 100%;
  }
}

.mradio-small {
  .mat-radio-label-content {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 0.8em;
  }
}

.mcard-less-padding.mat-card {
  padding: 0.5em;
}
.mat-step-label .mat-step-optional {
  display: none;
}

.mat-icon-no-overlay.mat-mdc-icon-button {
  .mat-button-focus-overlay {
    display: none !important;
  }
}

.menu-position-right {
  position: absolute;
  top: -48px; // Same size as the height of the left navbar circle with letters button.
  left: 60px; // Same size as the left navbar width.
}

.custom-mat-option.mat-option {
  font-size: $wlm-font-size;
  line-height: $select-option-height !important;
  height: $select-option-height !important;
}

.related-elements-compact {
  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0px;
  }

  .mat-chip-list-wrapper {
    padding: 0 2% 0 2%;
  }
}

.custom-search-field {
  .mat-form-field-flex {
    > .mat-form-field-infix,
    > .mat-mdc-form-field-infix {
      padding: 0.4em 0px !important;
    }
  }

  .mat-form-field-label-wrapper {
    top: -14px !important;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
  }
}

.custom-button-toggle {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 24px;
  }
}

.custom-form-field-bottom-space {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    padding-bottom: 1em;
  }
}

.custom-dialog-content-inline {
  mat-dialog-content.mat-dialog-content {
    display: inline;
  }
}
