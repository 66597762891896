@use 'wlm-theme' as *;
@use '../basic/wtr-mixins.scss' as *;

.vertical-center-icon {
  display: flex;
  justify-content: center;
}

.mat-mdc-unelevated-button > .mat-icon {
  margin: 0px !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.wtr-nav-link-icon {
  .inner-icon {
    color: var(--w-nav-bar-icon-color) !important;
  }
}

.wtr-nav-link-icon-disabled {
  .inner-icon {
    color: var(--w-nav-bar-icon-disabled) !important;
  }
}

.collapsible-button {
  .mat-icon {
    min-width: $wtr-icon-md-size;
  }
}

@mixin wtr-icon-size($icon-size) {
  &.wtr-icon,
  &.wtr-icon .mat-icon,
  &.wtr-icon .mat-icon > svg {
    @include wtr-size-rules($icon-size);
  }
}

.wtr-icon-md-size {
  @include wtr-icon-size($wtr-icon-md-size);
}

.wtr-icon-sm-size {
  @include wtr-icon-size($wtr-icon-sm-size);
}

.wtr-icon-xs-size {
  @include wtr-icon-size($wtr-icon-xs-size);
}

.wtr-icon-lg-size {
  @include wtr-icon-size($wtr-icon-lg-size);
}

.vertical-center-icon {
  display: flex;
  justify-content: center;
}

.default-icon-color {
  color: var(--w-default-icon-color);
}
