@use 'wlm-theme' as *;

@mixin wtr-mat-form-field-color($color) {
  .mat-form-field-outline,
  .mat-form-field-outline-thick {
    color: $color;
  }

  .mat-form-field-underline {
    background-color: $color;
  }
}

.mdc-text-field {
  // The default was breaking the positioning of the color picker popup.
  will-change: unset !important;
}

.mat-form-field {
  &.mat-focused {
    @include wtr-mat-form-field-color(var(--w-primary-color));
  }

  &:hover {
    @include wtr-mat-form-field-color(var(--w-primary-color));
  }

  &.mat-form-field-invalid {
    @include wtr-mat-form-field-color(var(--w-error-color));

    .mat-form-field-label {
      color: var(--w-error-color);
    }

    .mat-form-field-ripple {
      background-color: var(--w-error-color);
    }
  }
}

.field-stretch {
  width: 100%;
}

.field-no-padding-bottom {
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0px;
  }
}

.mat-error {
  color: var(--w-error-color);
}

.mat-form-field {
  label.mat-form-field-empty mat-label {
    font-size: $wtr-input-font-size;
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--w-primary-color);
}

.fix-field-label-overlap {
  .mat-mdc-form-field-infix {
    .mat-form-field-label,
    .mat-mdc-floating-label {
      mat-label {
        padding-right: $wtr-main-space;
        background-color: var(--w-component-bg-color);
      }
    }
  }
}

wlm-enhanced-input-select {
  .items-selected {
    .mat-mdc-select-value {
      display: none;
    }
  }

  .mat-mdc-select-trigger {
    justify-content: flex-end;
  }

  .mat-mdc-form-field-infix {
    display: inline !important;
    margin-top: $wtr-main-space !important;
    margin-bottom: $wtr-main-space !important;
  }
}

.enhanced-input-select-panel {
  .mdc-list-item--selected {
    .mat-mdc-option-pseudo-checkbox {
      display: block;
    }
  }

  .mat-pseudo-checkbox-full {
    margin-right: $wtr-main-space !important;
  }
}

.mat-mdc-select,
.mat-mdc-form-field {
  font-size: $wtr-input-font-size !important;
}

.mdc-list-item--selected {
  .mat-mdc-option-pseudo-checkbox {
    display: none;
  }
}

input.hide-numeric-input-styles {
  border-style: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    color: none;
    background-color: none;
  }
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.mat-mdc-option.mdc-list-item {
  min-height: $wtr-input-list-item-height !important;
  font-size: $wtr-input-font-size;
}

mat-form-field.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 0.8;

  .mat-mdc-form-field-infix {
    > .mat-mdc-chip-set,
    .mat-mdc-chip-grid {
      padding-top: $wtr-main-space;
      padding-bottom: $wtr-main-space;

      .mat-mdc-chip {
        font-size: $wtr-input-font-size;
      }
    }

    .mat-mdc-form-field-textarea-control {
      margin-top: $wtr-main-space;
      margin-bottom: $wtr-main-space;
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--w-primary-color) !important;
}

.mat-option {
  height: $wtr-input-list-item-height !important;
}

.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
}

.mat-mdc-select {
  display: flex;
}

.hide-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.hide-border {
  .mdc-text-field--outlined {
    &:not(.mdc-text-field--disabled) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border: 0px !important;
      }
    }
  }
}

.fixed-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    height: $wtr-main-space * 3;
  }
}

.extends-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    height: auto;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  height: $wtr-main-space * 3;
  display: flex;
  padding: 0 $wtr-main-space;

  &.mat-mdc-form-field-subscript-dynamic-size {
    height: 0px;
  }

  .mat-mdc-form-field-error {
    display: flex;
    margin-bottom: 4px;
  }
}

.fix-field-label-top-overlap {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      margin-top: $wtr-main-space;
    }
  }
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--w-primary-color) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--w-primary-color) !important;
  --mdc-checkbox-selected-icon-color: var(--w-primary-color) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--w-primary-color) !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--w-primary-color) !important;
  --mdc-radio-selected-hover-icon-color: var(--w-primary-color) !important;
  --mdc-radio-selected-icon-color: var(--w-primary-color) !important;
  --mdc-radio-selected-pressed-icon-color: var(--w-primary-color) !important;
  --mat-mdc-radio-checked-ripple-color: var(--w-primary-color) !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.custom-small-field {
  .mat-mdc-form-field-infix {
    min-height: $wtr-form-field-sm-height !important;
  }

  .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    top: divide($wtr-form-field-sm-height, 2) !important;
  }
}

.custom-search-field-size {
  .mat-mdc-form-field-infix {
    min-height: $wtr-search-field-height !important;
  }

  .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    top: divide($wtr-search-field-height, 2) !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    padding-bottom: 4px;
  }
}

.fix-field-align-suffix {
  .mat-mdc-form-field-icon-suffix {
    > span {
      padding-right: $wtr-main-space * 2;
    }
  }
}

.nested-checkbox-radio-list {
  .mat-mdc-checkbox,
  .mat-mdc-radio-button {
    flex-grow: 1;

    > .mdc-form-field {
      display: flex !important;

      &.mdc-form-field--align-end {
        display: flex;

        label {
          margin-left: 0 !important;
          margin-right: auto !important;
        }
      }
    }
  }
}

.checkbox-list {
  .mat-mdc-checkbox {
    label {
      margin-left: 0 !important;
      margin-right: auto !important;
    }
  }
}

.radio-list {
  .mat-mdc-radio-button {
    label {
      margin-left: 0 !important;
      margin-right: auto !important;
    }
  }
}

.mat-mdc-form-field {
  width: 100%;
  // margin-bottom: 4px;
  .mat-mdc-form-field-infix {
    min-height: $wtr-form-field-height;
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0;
}

mat-option {
  line-height: 3em;
  height: 3em;
}

.mat-mdc-option .mdc-list-item__primary-text {
  display: inline-block;
  flex-grow: 1;
  text-overflow: ellipsis;
  line-height: 1.2 !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  padding-bottom: 6px;
}

.mat-mdc-form-field-subscript-wrapper {
  .mat-mdc-form-field-error-wrapper {
    position: unset;
    padding: 0;
  }
}

.mdc-text-field--outlined {
  &:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--w-form-field-outline-color) !important;
    }

    &:hover,
    &:focus,
    &:active {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--w-form-field-outline-hover-color) !important;
      }
    }
  }

  &.mdc-text-field--disabled {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--w-form-field-outline-disabled-color) !important;
    }

    &:hover,
    &:focus,
    &:active {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--w-form-field-outline-disabled-color) !important;
      }
    }
  }
}

.mdc-text-field:not(.mdc-text-field--invalid) {
  .mdc-floating-label {
    color: var(--w-form-field-label-font-color) !important;
  }
}

.mat-form-field-appearance-outline.mat-form-field-invalid {
  .mdc-text-field--outlined {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--w-error-color) !important;
    }
  }
}

.mcd-radio {
  label {
    font-size: $wtr-input-font-size !important;
  }
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0px !important;
}

.custom-textarea-field {
  mat-form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .mat-mdc-text-field-wrapper {
      padding: 0 0.75em;

      .mat-mdc-form-field-flex {
        height: 100%;

        .mat-mdc-form-field-infix {
          padding: 1em 0 !important;

          > textarea {
            height: 100% !important;
          }
        }
      }
    }
  }
}

.mat-figure,
mat-label {
  font-size: $wtr-input-font-size !important;
}

.mat-mdc-form-field {
  .mdc-notched-outline__notch {
    border-left: 0px !important;
  }
}

.wtr-textarea-editor {
  textarea {
    padding: $wtr-main-space * 2 !important;

    &.mat-mdc-form-field-input-control {
      padding: $wtr-main-space !important;
    }
  }
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
  display: contents;
}

textarea.textarea-height-standard {
  height: 64px !important;
}
