@use '../functions/w-functions.scss' as water;

.w-theme-default {
  @include water.define-w-color(--w-primary-color, #1e6ab1);
  @include water.define-w-color(--w-primary-color-a700, #377cff);
  @include water.define-w-color(--w-primary-color-a400, #518dff);
  @include water.define-w-color(--w-primary-color-a200, #84aeff);
  @include water.define-w-color(--w-primary-color-a100, #b7d0ff);
  @include water.define-w-color(--w-primary-color-900, #0a3c88);
  @include water.define-w-color(--w-primary-color-800, #124d98);
  @include water.define-w-color(--w-primary-color-700, #1657a1);
  @include water.define-w-color(--w-primary-color-600, #1a62aa);
  @include water.define-w-color(--w-primary-color-500, #1e6ab1);
  @include water.define-w-color(--w-primary-color-400, #4080bd);
  @include water.define-w-color(--w-primary-color-300, #6297c8);
  @include water.define-w-color(--w-primary-color-200, #8fb5d8);
  @include water.define-w-color(--w-primary-color-100, #bcd2e8);
  @include water.define-w-color(--w-primary-color-50, #e4edf6);

  --w-private-neutral-color-black: #192122;
  --w-private-neutral-color-gray-light: #d8e1e3;
  --w-private-neutral-color-black-2: #334042;
  --w-private-neutral-color-gray-dark: #606c6e;
  --w-private-neutral-color-gray: #a6b0b4;
  --w-private-neutral-color-gray-2: #c5d0d4;
  --w-private-neutral-color-gray-3: #545c69; // Previous $grey3-color
  --w-private-neutral-color-white-light: #eee;
  --w-private-neutral-color-white-light-2: #ccc;
  --w-private-neutral-color-white: #fff;

  // Color fonts

  --w-main-font-color: var(--w-private-neutral-color-black);
  --w-primary-color-contrast-font-color: var(--w-private-neutral-color-white);
  --w-error-contrast-font-color: var(--w-private-neutral-color-white);

  --w-private-light-font: var(--w-private-neutral-color-gray-light);
  --w-private-dark-font: var(--w-private-neutral-color-black);

  --w-primary-color-contrast-50: var(--w-private-dark-font);
  --w-primary-color-contrast-100: var(--w-private-dark-font);
  --w-primary-color-contrast-200: var(--w-private-dark-font);
  --w-primary-color-contrast-300: var(--w-private-dark-font);
  --w-primary-color-contrast-400: var(--w-private-light-font);
  --w-primary-color-contrast-500: var(--w-private-light-font);
  --w-primary-color-contrast-600: var(--w-private-light-font);
  --w-primary-color-contrast-700: var(--w-private-light-font);
  --w-primary-color-contrast-800: var(--w-private-light-font);
  --w-primary-color-contrast-900: var(--w-private-light-font);
  --w-primary-color-contrast-a100: var(--w-private-dark-font);
  --w-primary-color-contrast-a200: var(--w-private-dark-font);
  --w-primary-color-contrast-a400: var(--w-private-dark-font);
  --w-primary-color-contrast-a700: var(--w-private-light-font);

  // Main colors
  --w-main-bg-color: #f0f0f0;
  --w-component-bg-color: #ffffff;
  --w-main-border-color: var(--w-private-neutral-color-gray-2);
  --w-popup-bg-color: var(--w-main-bg-color);
  @include water.define-w-color(--w-error-color, #cc2e29);
  --w-warning-color: #d9950b;
  --w-success-color: #19811a;
  --w-list-item-bg-color: rgba(77, 168, 229, 0.05);
  --w-app-title-font-color: #363d42;
  --w-page-title-font-color: var(--w-private-neutral-color-black);
  --w-cart-bg-color: var(--w-private-neutral-color-gray-light);
  --w-default-icon-color: var(--w-private-neutral-color-black);

  // Buttons
  @include water.define-w-color(--w-button-font-color, #334042);
  --w-button-regular-font-color: var(--w-primary-color);
  --w-button-regular-bg-color: var(--w-private-neutral-color-white);
  --w-button-toggle-bg-color: #424242;
  --w-button-toggle-color: #ffffff;
  --w-form-inner-title-color: var(--w-private-neutral-color-gray-dark);
  --w-nav-button-disabled: rgba(30, 106, 177, 0.38);
  --w-button-outline-border: rgba(0, 0, 0, 0.12);

  --w-disabled-color-alpha: 0.38;
  --w-primary-color-disabled: #{water.w-color-alpha(
      var(--w-primary-color),
      var(--w-disabled-color-alpha)
    )};
  --w-button-font-color-disabled: #{water.w-color-alpha(
      var(--w-button-font-color),
      var(--w-disabled-color-alpha)
    )};
  --w-error-color-disabled: #{water.w-color-alpha(
      var(--w-error-color),
      var(--w-disabled-color-alpha)
    )};

  --w-headline-font-color: var(--w-private-neutral-color-black);
  --w-title-font-color: var(--w-private-neutral-color-black);
  --w-subheading-font-color: var(--w-private-neutral-color-black-2);
  --w-filter-item-header-color: var(--w-private-neutral-color-black-2);
  --w-filter-field-label-color: var(--w-private-neutral-color-gray-3);

  @include water.define-w-color(--w-nav-bar-font-color, #606c6e);
  @include water.define-w-color(--w-nav-bar-link-color, #545c69);
  --w-nav-bar-icon-color: var(--w-private-neutral-color-gray-3);
  --w-nav-bar-icon-disabled-color: rgba(0, 0, 0, 0.2);
  --w-nav-bar-menu-hover-color: rgba(77, 168, 229, 0.15);

  // Scrollbars
  --w-scrollbar-color: #888;
  --w-scrollbar-bg-color: var(--w-private-neutral-color-white-light);

  // Golden Layout
  --w-gl-tab-header-bg-color: #ecf4fa;
  --w-gl-tab-header-border-color: #eaeaea;
  --w-gl-tab-header-font-color: var(--w-private-neutral-color-black-2);
  --w-gl-tab-header-button-color: var(--w-private-neutral-color-gray);
  --w-gl-subtitle-header-font-color: #606c6e;
  --w-gl-icon-color: var(--w-private-neutral-color-black-2);
  --w-gl-drag-icon-color: #545c69;
  --w-gl-drag-icon-color-unfocused: var(--w-private-neutral-color-gray-2);
  --w-gl-level-1-header-bg-color: var(--w-component-bg-color);
  --w-gl-level-2-header-bg-color: #f6f5f5;
  --w-gl-level-2-header-shadow-color: rgba(205, 205, 205, 0.37);
  --w-gl-level-3-header-bg-color: var(--w-gl-level-2-header-bg-color);
  --w-gl-item-border-color: #cdcdcd;

  // Grid
  --w-grid-alt-bg-color: #ecf4fa;
  --w-grid-hover-color: #deeaf4;
  --w-grid-header-font-color: var(--w-private-neutral-color-black-2);
  --w-grid-icon-color: var(--w-private-neutral-color-gray);
  --w-grid-header-drag-icon-color: var(--w-private-neutral-color-gray-light);
  --w-grid-header-drag-icon-color-hover: var(--w-private-neutral-color-gray);
  --w-grid-selector-column-main-font-color: #3f3f3f;
  --w-grid-selector-column-secondary-font-color: #717572;
  @include water.define-w-color(--w-grid-header-accent-color, #545c69);
  --w-grid-footer-bg-color: var(--w-main-bg-color);
  --w-grid-pdf-export-font-color: #888;
  --w-grid-filter-popup-bg-color: var(--w-component-bg-color);
  --w-grid-border-color: #d8d8d8;
  --w-grid-header-bg-color: var(--w-component-bg-color);
  --w-grid-header-filter-icon-color: rgba(0, 0, 0, 0.54);
  --w-grid-header-filter-filtered-icon-color: var(--w-primary-color);
  --w-grid-pager-font-color: rgba(0, 0, 0, 0.54);
  --w-grid-pager-font-disabled-color: rgba(0, 0, 0, 0.27);
  --w-grid-row-clicked-bg-color: #{water.w-color-alpha(var(--w-primary-color), 0.2)};

  // Right Panel
  --w-rp-title-color: var(--w-main-font-color);
  --w-rp-bg-color: var(--w-component-bg-color);
  --w-rp-handle-bg-color: #fff;
  --w-rp-handle-border-color: #ccc;

  --w-separator-color: #beccd6;
  --w-separator-color-secondary: #beccd660;

  --w-comment-header-color: rgba(0, 0, 0, 0.54);

  --w-alert-critical-bg-color: #cc0000;
  --w-alert-critical-font-color: var(--w-private-neutral-color-white);
  --w-alert-high-bg-color: #e67300;
  --w-alert-high-font-color: var(--w-private-neutral-color-black);
  --w-alert-medium-bg-color: #ffcc00;
  --w-alert-medium-font-color: var(--w-private-neutral-color-black);
  --w-alert-low-bg-color: #99cc00;
  --w-alert-low-font-color: var(--w-private-neutral-color-black);

  // Kendo Tree
  --w-tree-leaf-selected-bg-color: var(--w-primary-color);
  --w-tree-leaf-selected-bg-color: #d8d8d8;
  --w-selector-tree-leaf-bg-color: #fff;
  --w-selector-tree-leaf-hover-bg-color: #fff;
  --w-selector-tree-leaf-selected-bg-color: #fff;
  --w-selector-tree-leaf-selected-color: var(--w-primary-color);

  // Drag & drop List
  --w-drag-list-container-border-color: var(--w-primary-color);
  --w-drag-list-container-bg-color: #f5f5f5;
  --w-drag-list-item-bg-color: white;
  --w-drag-list-border-color: #cccccc;
  --w-drag-list-selected-bg-color: var(--w-primary-color-400);
  --w-drag-list-bg-color: white;
  --w-drag-scrollbar-bg-color: #f5f5f5;
  --w-drag-scrollbar-thumb-bg-color: rgb(221, 220, 220);
  --w-drag-list-inner-margin-color: #d4edff;

  // Map
  --w-map-filter-item-color: #334042;
  --w-map-filter-button-color: #fa6400;
  --w-map-filter-tab-label-font-color: #888;
  --w-map-filter-tab-label-bg-color: var(--w-private-neutral-color-white-light);
  --w-map-filter-tab-label-active-font-color: var(--w-primary-color);
  --w-map-filter-tab-label-active-bg-color: var(--w-private-neutral-color-white);
  --w-map-drag-icon-color: var(--w-private-neutral-color-gray-light);
  --w-map-time-bar-bg-color: rgba(0, 0, 0, 0.4);
  --w-map-time-bar-slider-color: #4cda22;
  --w-map-time-bar-pointer-color: white;
  --w-map-time-bar-font-color: var(--w-private-neutral-color-white);
  --w-map-layer-item-color: var(--w-private-neutral-color-gray-light);

  --w-menu-item-selected-color: #545c69;

  --w-input-disabled-bg-color: #fafafa;
  --w-checkbox-border-color: var(--w-primary-color);

  --w-no-data-message-color: #777;

  --w-value-list-label-color: var(--w-private-neutral-color-black);

  // Pills
  --w-pill-primary-font-color: var(--w-private-neutral-color-white);
  --w-pill-primary-bg-color: var(--w-primary-color);
  --w-pill-red-font-color: var(--w-private-neutral-color-white);
  --w-pill-red-bg-color: var(--w-error-color);
  --w-pill-white-font-color: var(--w-private-neutral-color-black);
  --w-pill-white-bg-color: var(--w-private-neutral-color-white);
  --w-pill-green-font-color: var(--w-private-neutral-color-white);
  --w-pill-green-bg-color: var(--w-success-color);
  --w-pill-yellow-font-color: var(--w-private-neutral-color-white);
  --w-pill-yellow-bg-color: var(--w-warning-color);

  --w-default-component-box-shadow-color: var(--w-private-neutral-color-white-light-2);

  // Alarms
  --w-alarm-counter-border-color: var(--w-private-neutral-color-black);
  --w-alarm-counter-base-color: var(--w-private-neutral-color-black);
  --w-alarm-counter-light-color: var(--w-private-neutral-color-white-light);
  --w-alarm-selected-bg-color: lightgray;

  // Spinner
  --w-spinner-overlay-font-color: #fff;
  --w-spinner-overlay-main-section-bg-color: #3dcd58;
  --w-spinner-overlay-secondary-section-bg-color: #3dcd5756;

  // Generic Card
  --w-generic-card-title1-font-color: #334042;
  --w-generic-card-subtitle-font-color: #252525;
  --w-generic-card-secondary-font-color: #334042;

  // Mat Menu
  --w-menu-option-selected-bg-color: rgb(190, 190, 190);

  // Spinner
  --w-spinner-bg-color: rgba(255, 255, 255, 0.5);
  --w-spinner-bg-color-opaque: rgba(255, 255, 255);

  // Form Fields
  --w-form-field-outline-color: rgba(0, 0, 0, 0.12);
  --w-form-field-outline-hover-color: var(--w-primary-color);
  --w-form-field-outline-disabled-color: rgba(0, 0, 0, 0.12);
  --w-form-field-label-font-color: rgba(0, 0, 0, 0.6);

  // Swal
  --w-swal-font-color: var(--w-main-font-color);
  --w-swal-bg-color: var(--w-component-bg-color);
  --w-swal-bg-success-color: #e3f0e4;
  --w-swal-success-icon-color: #5ea75f;
  --w-swal-bg-error-color: #f5e3e3;
  --w-swal-error-icon-color: #b83636;
  --w-swal-bg-warning-color: #faf2e2;
  --w-swal-warning-icon-color: #dfa530;
  --w-swal-bg-info-color: #e4edf6;
  --w-swal-info-icon-color: #4080bd;

  // Filters
  --w-filter-item-option-icon-color: #545c69;

  // Calendar
  --w-calendar-button-bg-color: var(--w-button-regular-bg-color);

  // Other
  --w-historical-form-container-border-color: #d3d3d3;
  --w-switch-selected-icon-color: var(--w-primary-color-contrast-font-color);

  // MDC Variables
  --mdc-checkbox-selected-checkmark-color: var(--w-component-bg-color);

  //Shadows (box-shadow)
  --w-shadow-high: 0px 4px 4px 0px rgba(204, 204, 204, 1);
  --w-shadow-medium: 0px 1px 4px 0px rgba(204, 204, 204, 1);
  --w-shadow-low: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  --w-shadow-gl-item: 0px 1px 1px 0px var(--w-gl-item-border-color),
    0px -1px 1px 0px var(--w-gl-item-border-color), 1px 0px 1px 0px var(--w-gl-item-border-color),
    -1px 0px 1px 0px var(--w-gl-item-border-color);
}
