// Contains basic styles that are applied to standard HTML elements, like "body", "h1", etc.
// Can also contain fonts declarations, layouts...

@use './wtr-mixins.scss' as *;

h1 {
  @include wtr-headline;
}

h2 {
  @include wtr-title;
}

h3 {
  @include wtr-subheading;
  padding-bottom: 0px;
}
