@use 'wlm-theme' as *;

.widget-info-expansion-panel {
  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      font-size: $wtr-tabs-font-size;
      font-family: $wtr-tabs-font-family;
      color: var(--w-main-font-color);
      font-weight: bold;
      white-space: nowrap;
    }

    .mat-expansion-panel-header-description {
      font-size: $wtr-main-font-size;
      font-family: $wtr-main-font-family;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .expansion-panel-header-description-section {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .mat-expansion-panel-body {
    line-height: $wtr-main-space * 2;
  }
}
