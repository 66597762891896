@use '../basic/wtr-variables.scss' as *;

ngx-mat-month-view .mat-calendar-body-selected {
  background-color: var(--w-primary-color);
  color: var(--w-primary-color-contrast-font-color);
}

.date-range-internal-margin {
  .date-range-form-horizontal > mat-form-field {
    margin-left: 4px;
  }
}

.mat-datepicker-content {
  .mat-calendar-previous-button,
  .mat-calendar-next-button,
  .mat-calendar-period-button,
  .mat-icon-button,
  .mat-stroked-button {
    background-color: var(--w-calendar-button-bg-color);
  }
}
