@use 'wlm-theme' as *;

wlm-generic-grid,
wlm-local-grid,
.water-grid {
  .keep-select-all-width {
    $grid-checkbox-column-width: 21px;

    .k-grid-header-locked {
      .k-table-th:first-child {
        width: $grid-checkbox-column-width !important;
        max-width: $grid-checkbox-column-width !important;
        min-width: $grid-checkbox-column-width !important;

        padding-left: 12px !important;
        padding-right: 12px !important;
      }
    }
    .k-table-td:first-child {
      width: $grid-checkbox-column-width !important;
      max-width: $grid-checkbox-column-width !important;
      min-width: $grid-checkbox-column-width !important;
    }
  }

  .k-grid td,
  .k-grid th {
    border-width: 0 !important;
  }
  //Dropdown items in filters
  .k-list .k-item {
    font-size: $wtr-filter-input-font-size !important;
  }

  .k-grid tr:hover td {
    background: var(--w-grid-hover-color) !important;
  }

  //checkbox selection effect in locked columns
  .k-grid .k-grid-content-locked .k-state-selected td {
    background: linear-gradient(
      0deg,
      w-color-alpha(var(--w-primary-color), 0) 0%,
      w-color-alpha(var(--w-primary-color), 0) 100%
    ) !important;
  }

  .k-grid-header {
    .k-grid-filter,
    .k-grid-filter-menu {
      .k-i-filter {
        -webkit-mask-image: url(/assets/icons/wtr-icons/24x24/grid_filter.svg);
        mask-image: url(/assets/icons/wtr-icons/24x24/grid_filter.svg);
        background-color: var(--w-grid-header-filter-icon-color);
        height: $wtr-icon-md-size;
        width: $wtr-icon-md-size;

        &::before {
          content: none !important;
        }
      }

      .k-state-active,
      &.k-active {
        background-color: transparent !important;

        .k-i-filter {
          -webkit-mask-image: url(/assets/icons/wtr-icons/24x24/grid_filter_filtered.svg);
          mask-image: url(/assets/icons/wtr-icons/24x24/grid_filter_filtered.svg);
          background-color: var(--w-grid-header-filter-filtered-icon-color);
        }
      }
    }

    .k-filterable {
      padding-right: 0px !important;

      .grid-header-icons {
        padding-right: $wtr-main-space * 5;
      }
    }
  }

  .k-grid td > span {
    padding: 0 6px !important;
  }

  th span span.k-link {
    min-width: $wtr-main-space * 9 !important;
  }

  th kendo-grid-filter-menu a {
    top: 10px !important;
  }

  th span.k-cell-inner:hover {
    .icon-position {
      color: var(--w-grid-icon-color);
    }
  }

  div[role='presentation'].k-grid-header-locked {
    border-width: 0 2px 0 0;
  }
  kendo-grid div[role='grid'] {
    border-radius: 4px !important;
  }

  kendo-grid div[role='grid'] div[role='presentation'].k-grid-header {
    padding: 0px $wtr-main-space + 2 0px 0px !important;
  }

  kendo-grid-list div[role='presentation'].k-grid-content-locked {
    height: 100% !important;
  }

  box-shadow: var(--w-shadow-medium);
  -webkit-box-shadow: var(--w-shadow-medium);
  -moz-box-shadow: var(--w-shadow-medium);
  border-radius: 4px;

  .k-input-value-text-solid,
  .k-picker-solid,
  .k-input-solid {
    background-color: transparent;
    color: inherit;
    border-color: inherit;
  }

  .k-grid {
    background-color: var(--w-component-bg-color);
    color: var(--w-main-font-color);
    border-radius: 4px;

    .k-table-alt-row {
      background-color: var(--w-primary-color-300);
      color: var(--w-main-font-color);
    }

    .k-table,
    .k-data-table,
    .k-grid-content,
    .k-pager-wrap {
      background-color: var(--w-component-bg-color);
      color: var(--w-main-font-color);
    }

    .k-pager {
      border-radius: 0 0 4px 4px !important;

      .k-pager-input {
        .k-input-md .k-input-inner,
        .k-picker-md .k-input-inner {
          padding-inline: $wtr-main-space !important;
        }
      }
    }

    .k-table-thead {
      background-color: var(--w-grid-header-bg-color);
      color: var(--w-main-font-color);
    }
  }

  .k-grid-md .k-table-th > .k-cell-inner {
    width: 100%;
  }
}

.grid-header-template {
  font-size: $wtr-main-font-size;
  font-family: $wtr-grid-header-font-family;
  font-weight: $wtr-font-weight-500;
}

kendo-grid-filter-menu:hover {
  background: unset;
  border-radius: unset;
}

kendo-grid-filter-menu {
  margin-top: -5px;
}

.k-grid {
  .k-table-row {
    &:hover {
      background: var(--w-grid-hover-color);
    }

    &.k-table-alt-row {
      background: var(--w-grid-alt-bg-color);
    }

    &.rowclicked,
    &.k-state-selected,
    &.k-selected {
      background: var(--w-grid-row-clicked-bg-color) !important;
    }
  }

  .k-grid-content-locked {
    .k-table-alt-row {
      &.k-selected {
        .k-table-td {
          background: w-color-alpha(var(--w-primary-color), 0.02) !important;
        }
      }
    }
  }
}

td span.column-cell {
  font-family: $wtr-grid-font-family;
  font-size: $wtr-main-font-size;
}

.grid-header-icons {
  .mat-icon {
    margin-top: 6px;
  }
}

/**************
  Grid filters
**************/
.grid-filter-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 24px 14px 0 14px;
  font-size: 10px !important;

  .radio-group-container > .radio-item > label {
    font-size: $wtr-filter-input-font-size !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.grid-filter-container mat-form-field {
  width: 245px;
  .mat-form-field-wrapper,
  .mat-mdc-text-field-wrapper {
    margin: 0 0 $wtr-main-space 0 !important;
    padding: 0 $wtr-main-space * 2 !important;
  }

  .mat-form-field-flex {
    > .mat-form-field-infix,
    > .mat-mdc-form-field-infix {
      padding-top: 5px !important;

      .mat-select,
      input {
        font-family: $wtr-grid-filter-input-font-family !important;
        font-size: $wtr-filter-input-font-size !important;
      }
    }
  }

  .mat-form-field-flex .mat-form-field-outline {
    color: var(--w-primary-color) !important;
    height: 33px !important;
  }

  .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    height: $wtr-icon-md-size !important;
    width: $wtr-icon-md-size !important;
  }

  mat-datepicker-toggle > button {
    padding: 0 !important;
    top: -1px !important;
  }
}

.grid-filter-container
  mat-form-field.mat-form-field-disabled
  > .mat-form-field-wrapper
  > .mat-mdc-text-field-wrapper
  > .mat-form-field-flex
  .mat-form-field-outline {
  opacity: 0.5;
}

.grid-filter-container > kendo-multiselect,
.grid-filter-container > kendo-dropdownlist,
.grid-filter-container > kendo-numerictextbox,
.grid-filter-container > kendo-textbox {
  margin: 0 !important;
  width: 100% !important;
  padding-bottom: $wtr-main-space !important;

  div.k-multiselect-wrap.k-floatwrap,
  span.k-input-value-text-inner,
  span.k-numeric-wrap {
    border: 1px solid var(--w-primary-color) !important;
    border-radius: $wtr-fields-border-radius !important;
    padding-left: $wtr-main-space !important;
    font-family: $wtr-grid-filter-input-font-family !important;
    font-size: $wtr-filter-input-font-size !important;
  }
}

.grid-filter-container {
  .k-dropdownlist .k-input-value-text-inner .k-input-value-text,
  .k-dropdowntree .k-input-value-text-inner .k-input-value-text,
  .k-textarea,
  .k-textbox {
    font-size: $wtr-filter-input-font-size !important;
  }
}

.grid-filter-container > kendo-dropdownlist span.k-input-value-text {
  padding-top: $wtr-filter-input-font-size;
}

.grid-filter-container > kendo-textbox {
  border: 1px solid var(--w-primary-color) !important;
  border-radius: $wtr-fields-border-radius !important;
  padding: $wtr-main-space - 6 0 0 $wtr-main-space !important;
  font-family: $wtr-grid-filter-input-font-family !important;
  font-size: $wtr-filter-input-font-size !important;
}

.grid-filter-container > wlm-enumerable-column-filter {
  padding-bottom: $wtr-main-space;
}

.k-filter-menu .k-actions {
  justify-content: flex-end !important;
  margin-top: 9px !important;
  padding-bottom: 19px !important;

  .k-button {
    height: $wtr-grid-filter-button-height;
    width: $wtr-grid-filter-button-width;
    border-radius: $wtr-fields-border-radius;
    font-family: $wtr-grid-filter-button-font-family !important;
    font-size: $wtr-grid-filter-button-font-size;
    line-height: 11px;
    padding-top: 10px;
  }

  button[type='reset'] {
    background: var(--w-button-regular-bg-color);
    border: 0;
    color: var(--w-error-color) !important;
    font-size: $wtr-grid-filter-button-font-size;
  }
}

.k-filter-menu-container .k-actions {
  padding: $wtr-main-space $wtr-main-space * 2;
}

.k-button-group {
  box-shadow: none !important;
}

wlm-image-column-filter {
  padding-bottom: $wtr-main-space;

  input.k-checkbox {
    margin-right: divide($wtr-main-space, 2);
  }
}

kendo-grid-filter-menu {
  width: $wtr-main-space * 3;
  padding-right: $wtr-main-space;

  .k-grid-header-menu {
    width: $wtr-main-space * 3 !important;
  }
}

wlm-boolean-column-filter,
wlm-enumerable-column-filter {
  .mat-chip.mat-standard-chip {
    //background-color: neutral-color-white-light-2;
    //color: neutral-color-gray-dark;
    margin: divide($wtr-main-space, 2) divide($wtr-main-space, 2) $wtr-main-space 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 2em;
    border-radius: 5em;
    font-size: $wtr-button-font-size;
    line-height: 2;
    text-transform: none;

    .mat-chip-remove.mat-icon,
    .mat-chip-remove .mat-icon {
      width: 14px;
      height: 14px;
      font-size: $wtr-main-font-size;
    }
  }

  .full-width {
    width: 100%;
  }

  .chip-list-wrapper {
    min-height: 3em;
  }

  .k-grid-filter-menu {
    svg path {
      fill: var(--w-grid-header-font-color);
    }
  }
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  background-color: inherit;
}

.k-grid-md .k-table-th > .k-cell-inner {
  padding-inline: 0px !important;
  margin-inline: 0px !important;

  & > .k-link {
    padding-inline: 0px !important;
    margin-inline: 0px !important;
  }
}

.k-grid-pager {
  .k-pager-nav {
    color: var(--w-grid-pager-font-color) !important;

    &.k-state-disabled {
      color: var(--w-grid-pager-font-disabled-color) !important;
    }
  }

  .k-i-caret-alt-down {
    color: var(--w-grid-pager-font-color) !important;
  }
}

.k-i-sort-desc-small::before,
.k-i-sort-asc-small::before {
  color: var(--w-grid-header-filter-icon-color);
}

.k-filtercell {
  color: var(--w-main-font-color);
}

.k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
  color: var(--w-grid-header-filter-icon-color);
}

.k-column-resizer:hover {
  color: var(--w-primary-color-400);
}

.k-table-td.k-focus {
  background-color: transparent !important;
}

//Comparison classes

.comparison-percentage {
  margin-left: $wtr-main-space;
}

.comparison-icon {
  vertical-align: middle;
  margin-left: $wtr-main-space;
  display: flex;

  .material-icons {
    font-size: 18px;
    align-self: center;
    height: 18px;
    width: 18px;
  }

  mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    align-self: center;
    display: flex;

    path.default-color {
      fill: var(--w-default-icon-color);
    }
  }
}
