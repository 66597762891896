@use 'wlm-theme' as *;

.mat-menu-item:hover:not([disabled]) {
  background: var(--w-nav-bar-menu-hover-color) !important;
}

img.splash {
  position: fixed;
  width: 875px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

mat-slide-toggle.custom-slide {
  width: 100%;

  .mat-slide-toggle-bar {
    margin-left: auto;
  }
}
