@use '../basic/wtr-variables.scss' as *;

.flex-style {
  display: flex;
}

.flex-style-max {
  flex: 1 1 100%;
}

.icon-position {
  align-items: center;
  margin-left: 2 * $wtr-main-space;
}

.card-column {
  display: flex;
  overflow-x: auto;
  flex-direction: column;
}

.card-row {
  display: flex;
  flex-direction: row;
}

.separator {
  margin: 0 5px;
  font-size: $wtr-generic-card-title-font-size;
}

.single-label-card {
  .line-item {
    padding-bottom: 0px;
  }
}

.card-container {
  margin: $wtr-main-space $wtr-main-space $wtr-main-space 2 * $wtr-main-space;
  width: 100%;
  justify-content: center;
}

.card-left {
  max-width: 20%;
  display: flex;
  // removed for the selection widget in DV
  // margin-left: $wtr-main-space;
}

.card-right {
  //margin-right: 2 * $wtr-main-space;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: $wtr-main-space;
}

.title1 {
  text-transform: uppercase;
  font-family: $wtr-generic-card-title-font-family;
  font-size: $wtr-generic-card-title-font-size;
  font-weight: 700;
  color: var(--w-generic-card-title1-font-color);
  line-height: 14px;
  margin-bottom: 4px;
}

.subtitle {
  font-family: $wtr-generic-card-subtitle-font-family;
  font-size: $wtr-generic-card-subtitle-font-size;
  text-transform: uppercase;
  color: var(--w-generic-card-subtitle-font-color);
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 4px;
}

.secondary {
  font-family: $wtr-generic-card-secondary-font-family;
  font-size: $wtr-generic-card-secondary-font-size;
  color: var(--w-generic-card-secondary-font-color);
  line-height: 14px;
  font-weight: 400;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 10px);
}

.container-reduced {
  width: 60%;
}

.generic-card-small {
  min-height: 36px !important;
}
