@use 'wlm-theme' as *;
@use '../basic/wtr-mixins.scss' as *;

json-editor > div {
  width: 100% !important;
}

.jsoneditor-menu {
  background-color: var(--w-primary-color) !important;
  border-color: var(--w-primary-color) !important;
}

.jsoneditor {
  border-color: var(--w-primary-color) !important;
}
