@forward './variables/w-default-theme-variables.scss';
@forward './variables/w-dark-theme-variables.scss';

body {
  --w-default-component-box-shadow: 0 1px 4px 0 var(--w-default-component-box-shadow-color);
  // Only used for WtrColor/basic classes.
  --w-neutral-color-black-2: #334042;
  --w-neutral-color-white: #fff;

  --top-bar-z-index: 15;
}
