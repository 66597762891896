.spinner-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: var(--w-spinner-bg-color);
  z-index: 998;

  wlm-spinner {
    width: 6rem;
    height: 6rem;
  }
}

.spinner-inherit-size {
  height: inherit;
  width: inherit;
}
