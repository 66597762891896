@use 'wlm-theme' as *;

wlm-data-visualization-cart {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

wlm-data-visualization-chart-config-popup {
  .events-container {
    .main-slide {
      label {
        font-weight: $wtr-font-weight-100 * 6;
      }
    }

    mat-slide-toggle > div {
      width: 100%;

      button {
        margin-left: auto;
      }

      label {
        margin-left: 0;
      }
    }
  }
}
