@use 'wlm-theme' as *;

.map-time-bar-slider {
  &.ngx-slider {
    .ngx-slider-bubble {
      font-size: $wtr-map-control-font-size;
      color: var(--w-map-time-bar-pointer-color);

      &.ngx-slider-limit {
        color: var(--w-map-time-bar-pointer-color);
      }
    }

    .ngx-slider-pointer {
      width: 20px;
      height: 20px;
      top: -8px;
      background-color: var(--w-map-time-bar-pointer-color);

      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        top: unset;
        left: unset;
        background-color: var(--w-map-time-bar-slider-color);
      }

      &:hover:after {
        background-color: var(--w-map-time-bar-slider-color);
      }

      &.ngx-slider-active {
        &:after {
          background-color: var(--w-map-time-bar-slider-color);
        }
      }
    }

    .ngx-slider-bar {
      background-color: var(--w-map-time-bar-slider-color);
    }

    .ngx-slider-tick {
      background-color: var(--w-map-time-bar-slider-color);
    }
  }
}
