// Solution based on https://codyhouse.co/blog/post/how-to-combine-sass-color-functions-and-css-variables

/**
   * Define main color variable and auxiliary ones.
   * Convert HEX to RGBA color to create a new -rgb variable, 
   * that will be used for other functions, like alpha.
   * Example: 
   *  define-extended-color(--w-primary-color, #1fb0c1)
   *  Generated variables:
   *    --w-primary-color: #1fb0c1;
   *    --w-primary-color-rgb: 31, 176, 193;
   */
@mixin define-w-color($var-name, $color) {
  #{$var-name}: $color;
  $color-rgb: #{red($color) + ', ' + green($color) + ', ' + blue($color)};
  #{$var-name}-rgb: #{$color-rgb};
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

/**
   * This alpha function relies on the *-rgb variables created by define-extended-color,
   * so it can only be applied to variables created with that method.
   */
@function w-color-alpha($color, $opacity) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-rgb: var(#{$color + '-rgb'});
  @return rgba($color-rgb, $opacity);
}
