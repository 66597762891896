@use '../basic/wtr-variables.scss' as *;

.filters-background {
  background: var(--w-component-bg-color);
  border-radius: divide($wtr-main-space, 2);
  padding: $wtr-main-space;
  margin-bottom: $wtr-main-space;
  box-shadow: var(--w-shadow-medium);
  -webkit-box-shadow: var(--w-shadow-medium);
  -moz-box-shadow: var(--w-shadow-medium);
  overflow: auto;
}

.remove-filters-background {
  wlm-base-filter-container.filters-background,
  wlm-base-filter-container div.filters-background {
    background: transparent !important;
    border-radius: unset !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: unset !important;
  }
}

.filter-margin {
  wlm-base-filter-container.filters {
    margin: $wtr-main-space;
  }
}

.buttons-background {
  background: var(--w-component-bg-color);
  border-radius: divide($wtr-main-space, 2);
  box-shadow: var(--w-default-component-box-shadow);
  margin-bottom: divide($wtr-main-space, 2);
  padding: divide($wtr-main-space, 4) 0 divide($wtr-main-space, 4) 0;
  height: $wtr-trb-container-height;

  button {
    margin: 0 !important;
  }
}

.top-container .filter-buttons-container wlm-grid-buttons {
  display: flex;
  background: var(--w-component-bg-color);
  border-radius: divide($wtr-main-space, 2);
  box-shadow: var(--w-shadow-medium);
  -webkit-box-shadow: var(--w-shadow-medium);
  -moz-box-shadow: var(--w-shadow-medium);
  // margin-bottom: $wtr-main-space;
  // padding-bottom: divide($wtr-main-space, 2);
}

.top-container .filter-buttons-container .slot-wrapper {
  display: flex;
  align-items: center;

  & > span {
    display: flex;
    align-items: center;
  }
}

.top-container .filter-buttons-container .slot-wrapper:empty + .slot-separator,
.top-container .filter-buttons-container .slot-separator + .slot-wrapper:empty {
  display: none;
}

.top-container .filter-buttons-container .slot-separator {
  border-left: 2px solid var(--w-main-border-color);
  margin: $wtr-main-space 0;
}

.filter-container-self-space {
  padding-left: $wtr-main-space;
  padding-right: $wtr-main-space;
  padding-top: $wtr-main-space;
}

.button-position {
  display: flex;
  align-items: center;
}

.base-filter-container {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.filter-item-title {
  color: var(--w-filter-item-header-color);
  font-weight: bold;
}

.summary-main-title {
  color: var(--w-filter-item-header-color);
}

.summary-title {
  font-style: italic;
}

.base-filter-container {
  .base-filter {
    padding-top: $wtr-main-space;
  }
}

.form-filter {
  .filters {
    margin-bottom: $wtr-main-space * 3;
  }
}

.filters-container-form-mode {
  margin-left: -2 * $wtr-main-space;
  margin-right: -$wtr-main-space;
  margin-top: -$wtr-main-space;
}

.filter-select-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $wtr-main-space * 4;
}

.filter-item-option-icon {
  path {
    fill: var(--w-filter-item-option-icon-color);
    stroke: var(--w-filter-item-option-icon-color);
    stroke-width: 1%;
  }
}

.filter-select-item-label {
  margin: $wtr-main-space;
  margin-top: divide($wtr-checkbox-size, 2);
}

.filter-select-item-title {
  margin: 0px;
  margin-top: $wtr-main-space * 2;
}

.filter-select-item-title-check-all {
  margin: $wtr-main-space;
  margin-top: divide($wtr-checkbox-size, 2);
}

.align-lock-filters {
  .base-filter-container {
    .filters {
      padding-right: $wtr-main-space;
    }
  }
}
