/**
 * Import the font into CSS.
 * Note that assets paths must always start with "/".
 */

@font-face {
  font-family: 'Roboto Bold';
  src: url(/assets/fonts/wtr-theme/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url(/assets/fonts/wtr-theme/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url(/assets/fonts/wtr-theme/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: url(/assets/fonts/wtr-theme/Roboto-Black.ttf) format('truetype');
}
