/**
 * Corresponds to the wlm-nav-bar component.
 */
@use 'wlm-theme' as *;

$navbar-expanded: 240px;

#navbar {
  background-color: var(--w-component-bg-color);
  margin-right: $wtr-main-space !important;
  box-shadow: var(--w-shadow-medium) !important;

  .k-drawer-wrapper {
    overflow-x: unset;
    overflow-y: unset;
  }

  &.menu-expanded {
    flex-basis: $navbar-expanded !important; // 8*30

    .k-drawer-wrapper {
      width: $navbar-expanded !important; // 8*30
    }
  }

  ul > li:hover {
    background: rgba(77, 168, 229, 0.15);
  }

  .mat-content.mat-content-hide-toggle {
    margin-right: 0px;
  }

  .mdc-list {
    padding: 0px;
  }
}

.navbar-kendo-drawer {
  .k-drawer-wrapper {
    height: calc(100% - 140px);
    margin-top: 90px;
    overflow: auto !important;
  }
}

.navbar-kendo-drawer-collapsed {
  .k-drawer-wrapper {
    height: calc(100% - 140px);
    margin-top: 90px;
    overflow: hidden !important;
  }
}

.k-drawer {
  background-color: var(--w-component-bg-color);

  .k-drawer-items {
    background-color: var(--w-component-bg-color);
  }
}

.menu-link-container .mat-icon svg path,
#navbar mat-icon svg path {
  fill: var(--w-nav-bar-icon-color);
}

.k-drawer-content {
  background-color: var(--w-main-bg-color);
}
