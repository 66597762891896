@use '../basic/wtr-variables.scss' as *;

.box-title {
  font-size: $wtr-tabs-font-size;
  font-family: $wtr-tabs-font-family;
  color: var(--w-main-font-color);
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: $wtr-main-space;
}

.date-range-size {
  wlm-date-range-filter > div {
    max-height: $wtr-main-space * 5;
    display: flex;
  }
}
