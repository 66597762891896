@use 'wlm-theme' as *;

.mat-mdc-dialog-surface {
  padding: $wtr-main-space * 2;
  margin: $wtr-main-space;
  overflow-x: hidden;
}

.mat-mdc-dialog-content {
  // This is set in the material package as 65vh, but breaks some of our popups
  max-height: 90vh !important;
}

.custom-popup-hide-overflow {
  overflow: hidden !important;
}
