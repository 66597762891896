@use 'wlm-theme' as *;

.k-list-item.k-focus.k-selected {
  color: var(--w-primary-color);
}

.k-picker-solid:focus,
.k-picker-solid.k-focus {
  border-color: var(--w-primary-color);
}

.k-input-solid:disabled,
.k-input-solid[disabled],
.k-input-solid.k-disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: var(--w-button-font-color-disabled);
  background-color: inherit;
}

.k-dropdown,
.k-dropdownlist,
.k-picker {
  color: var(--w-main-font-color);
}
