/**
 * Classes used to easily remove the angular flex-layout package.
 * All props are !important because before, they were assigned to the element with "style".
 * Due to this, be sure to use this classes BEFORE some specific classes.

 * Good: class="wtr-fx-layout my-container"
 * Bad: class="my-container wtr-fx-layout"

 * This is because, if my-container tries to override a style of this class, 
 * it is impossibe to do it in the second case.
*/

.wtr-fx-layout {
  display: flex !important;
  flex-direction: row !important;
  box-sizing: border-box !important;
}

.wtr-fx-layout-column {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
}

.wtr-fx-flex-grow {
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-height: 100% !important;
}
