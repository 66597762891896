// "Previous" theme
@use './themes/previous-theme/previous-theme.scss' as *;
@use './themes/previous-theme/wlm-material-theme.scss' as *;

// WTR theme
@use './themes/aawl-theme/aawl-theme.scss' as *;
@use './themes/aawl-theme/basic/wtr-material-setup.scss' as *;

// Toggle this to enable the WTR theme.
$use-wtr-theme: true;

// Only one material theme must be imported at a time to avoid duplicated material files.
@if $use-wtr-theme {
  @include load-wtr-material-theme;
} @else {
  @include load-previous-material-theme;
}

// Style changes that come with material 14

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: unset !important; // Was 0
}
