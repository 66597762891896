@use 'wlm-theme' as *;
@use '../basic/wtr-mixins.scss' as *;

body {
  // Important: changing these sizes must sync with nativeLayoutHeaderSizes.
  --w-gl-level-1-header-height: #{$wtr-main-space * 3};
  --w-gl-level-2-header-height: #{$wtr-main-space * 3};
  --w-gl-level-3-header-height: #{$wtr-main-space * 3};
}

$wtr-gl-dropdown-tab-height: $wtr-main-space * 4;
$wtr-header-secondary-icon-size: $wtr-main-space * 2;
// Amount of icons at the tabs level of a header (not at each tab level)
// Currently 2: dropdown for collapsed tabs and maximise icon
$wtr-gl-header-icon-amount: 2;

.dl-version-2 {
  // This class must be added to all widgets that are rendered inside a GL layout, and must apply to the host element.
  .widget_position {
    position: absolute;
    overflow: hidden;
  }
}

// When we have a widget inside a widget, the second widget will have widget_position, and its
// styles may break some styles. Use this class in nested widgets to override that.
.widget-position-nested {
  position: inherit !important;
  overflow: inherit !important;
}

.dl-header-extend-container > * {
  display: inline-block;
}

.lm_goldenlayout {
  background: var(--w-component-bg-color);
}

.lm_content {
  background: var(--w-component-bg-color);
  overflow: auto;
  border: 0px;
  color: var(--w-main-font-color);
}

/** Splitter & handlers styles (allows moving & resizing tabs) **/

.lm_splitter {
  opacity: 0.001;
  transition: opacity 200ms ease;
  background: var(--w-primary-color);
  box-sizing: border-box;

  &:hover {
    background: var(--w-primary-color);
  }

  &.lm_vertical {
    border: 3px solid var(--w-main-bg-color);
    /*
    .lm_drag_handle {
      height: divide($wtr-main-space, 2) !important;
    }
    */
  }

  &.lm_horizontal {
    border: 3px solid var(--w-main-bg-color);
    /*
    .lm_drag_handle {
      width: divide($wtr-main-space, 2) !important;
    }
    */
  }

  :hover {
    background: none !important;
    opacity: 1;
  }
}

/** Drag & drop styles **/

.lm_dragProxy .lm_content {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.lm_dropTargetIndicator {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.4);
  outline: 1px dashed #ccc;
  transition: all 200ms ease;

  .lm_inner {
    background: #000;
    opacity: 0.1;
    filter: alpha(opacity=1);
  }
}

/** Headers styles **/

@mixin lm_tab {
  font-size: $wtr-tabs-font-size;
  padding-right: 0px;
  padding-bottom: 0px;
  margin: 0px;
  border: 0px;
  box-shadow: none;
  display: flex;
  align-items: center;

  &.lm_active {
    .lm_title {
      border-bottom: 2px solid var(--w-primary-color);
    }

    .custom_lm_settings_button {
      color: var(--w-gl-drag-icon-color) !important;
    }
  }

  i {
    position: relative;
  }

  .lm_left {
    @include wtr-icon-mask(url(/assets/icons/wtr-icons/24x24/drag_and_drops.svg));
    background-color: var(--w-gl-drag-icon-color-unfocused);
    width: 12px;
    min-width: 12px;
    height: 10px;
    min-height: 10px;
    display: inline-block;
    top: 1px;
    left: auto;
  }

  .lm_right {
    right: 0px;
  }

  .lm_title {
    font-size: $wtr-tabs-font-size;
    color: var(--w-gl-tab-header-font-color);
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 0px;
  }

  .custom_lm_settings_button {
    width: $wtr-header-secondary-icon-size;
    height: $wtr-header-secondary-icon-size;
    line-height: $wtr-header-secondary-icon-size;
    color: var(--w-gl-tab-header-button-color);
    display: inline-block;
    flex-grow: 1;
    margin-left: divide($wtr-main-space, 2);
  }

  .custom_lm_header_secondary_icon {
    mat-icon {
      font-size: $wtr-header-secondary-icon-size;
      color: var(--w-gl-icon-color);
    }
  }

  .lm_close_tab {
    display: inline-block;
    flex-grow: 1;
    position: relative;
    right: auto;
    top: auto;
    width: $wtr-main-space * 2;
    min-width: $wtr-main-space * 2;
    height: $wtr-main-space * 2;
    @include wtr-icon-mask(url(/assets/icons/wtr-icons/24x24/cancel.svg));
    background-color: var(--w-gl-icon-color);
    margin-left: divide($wtr-main-space, 2) - 1;
    background-image: none;
  }
}

.lm_header {
  display: flex;
  flex-flow: nowrap;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 3;
  width: inherit;

  .lm_tabs {
    height: inherit !important;
    background-color: transparent;
    display: contents;

    .lm_tab {
      @include lm_tab;
      height: inherit !important;
      &:only-child {
        margin-right: $wtr-main-space * 4 * $wtr-gl-header-icon-amount;
      }
    }
  }

  .lm_tabdropdown_list {
    right: $wtr-main-space;
    z-index: 999;

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;

    .lm_tab {
      @include lm_tab;
      display: inline-block;
      width: $wtr-main-space * 30;
      height: $wtr-gl-dropdown-tab-height !important;
      border-radius: 0px;
      border: 2px solid var(--w-gl-tab-header-border-color);
      border-bottom: 0px;
      padding: 0px;
      padding-left: $wtr-main-space;
      display: flex;
      align-items: center;

      .lm_title {
        width: $wtr-main-space * 23;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
          color: var(--w-primary-color);
        }
      }

      &:nth-last-of-type(1) {
        border-bottom: 2px solid var(--w-gl-tab-header-border-color);
      }

      .custom_lm_settings_button {
        float: right;
        padding-right: divide($wtr-main-space, 2);
      }
    }
  }

  .lm_controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: $wtr-main-space * 8;
    height: inherit;
    padding-right: $wtr-main-space;
    color: var(--w-primary-color);
    float: right;

    .lm_tabdropdown {
      margin-right: $wtr-main-space;
      &:before {
        color: var(--w-primary-color);
      }
    }

    .lm_maximise,
    .lm_popout {
      width: 18px;
      height: 18px;
      cursor: pointer;

      position: relative;
      background-position: center center;
      background-repeat: no-repeat;
      // opacity: 0.4;
      transition: opacity 300ms ease;
    }

    .lm_maximise {
      @include wtr-icon-mask(url(/assets/icons/wtr-icons/24x24/maximise.svg));
      background-color: var(--w-primary-color);
      background-image: none;
    }

    .lm_popout {
      @include wtr-icon-mask(url(/assets/icons/wtr-icons/24x24/popout.svg));
      background-color: var(--w-primary-color);
      background-image: none;
    }
  }
}

.lm_selected {
  .lm_header {
    background-color: var(--w-gl-tab-header-bg-color);
  }
}

.lm_maximised {
  .lm_header {
    background-color: var(--w-gl-tab-header-bg-color);
  }

  .lm_content {
    width: inherit !important;
  }
}

/** Misc styles **/

.lm_item {
  box-sizing: border-box;
  box-shadow: var(--w-shadow-gl-item);
}

.lm_transition_indicator {
  background-color: #000;
  border: 1px dashed #555;
}

.lm_popin {
  cursor: pointer;

  &:hover {
    .lm_icon {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .lm_bg {
    background: #000;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }

  .lm_icon {
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}

.nested-dynamic-layout {
  padding: $wtr-main-space;
}

wlm-dynamic-form form.form-container {
  // we can't use variables inside the calc function
  width: calc(100% - 21px) !important;
}

.dl-enable-closable-stacks {
  .lm_header {
    .lm_close {
      display: inline-block;
    }
  }
}

.dl-disable-closable-stacks {
  .lm_header {
    .lm_close {
      display: none !important;
    }
  }
}

.dl-disable-closable-widgets {
  .lm_header .lm_tabs .lm_tab {
    .lm_close_tab {
      display: none;
    }
  }
}

.dl-enable-closable-widgets {
  .lm_header .lm_tabs .lm_tab {
    .lm_close_tab {
      display: inline-block;
    }
  }
}

.dl-enable-popout-icons {
  .lm_header .lm_tabs .lm_tab {
    .lm_popout {
      display: inline-block !important;
    }
  }
}

.dl-disable-popout-icons {
  .lm_header .lm_tabs .lm_tab {
    .lm_popout {
      display: none !important;
    }
  }
}

.dl-hide-layout {
  .lm_goldenlayout {
    display: none !important;
  }
}

@mixin dl-border-radius-full {
  border-radius: $wtr-main-space;
}

@mixin dl-border-radius-top {
  border-radius: $wtr-main-space $wtr-main-space 0px 0px;
}

@mixin dl-level-3-border-radius-bottom {
  border-radius: 0px 0px $wtr-main-space $wtr-main-space;
}

.lm_goldenlayout {
  background: none;
  @include dl-border-radius-top;

  .lm_header {
    height: var(--w-gl-level-1-header-height) !important;
    background-color: var(--w-gl-level-1-header-bg-color);
    @include dl-border-radius-top;

    .lm_tabs,
    .lm_tabdropdown_list {
      background-color: var(--w-gl-level-1-header-bg-color);

      .lm_tab {
        background-color: var(--w-gl-level-1-header-bg-color);

        .lm_title {
          background-color: var(--w-gl-level-1-header-bg-color);
          color: var(--w-main-font-color);
          text-transform: uppercase;
          @include wtr-font-roboto-black;
        }

        &.lm_active {
          .lm_title {
            color: var(--w-primary-color);
            border-bottom: 1px solid var(--w-primary-color);
          }
        }
      }
    }

    .lm_tabs {
      .lm_tab {
        @include dl-border-radius-top;
      }
    }
  }

  .lm_item {
    @include dl-border-radius-top;

    .lm_content {
      @include dl-border-radius-top;
    }
  }

  &.lm_root,
  .lm_column,
  .lm_row {
    box-shadow: none;
  }
}

.dl-level-2,
.dl-level-3 {
  .lm_goldenlayout {
    @include dl-border-radius-full;
    box-shadow: none;
    background: var(--w-component-bg-color);

    .lm_header {
      height: $wtr-main-space * 3 !important;
      background-color: var(--w-gl-level-2-header-bg-color);
      box-shadow: 0px 1px 1px 0px var(--w-gl-item-border-color);
      z-index: 2;
      @include dl-border-radius-top;

      .lm_tabs,
      .lm_tabdropdown_list {
        background-color: var(--w-gl-level-2-header-bg-color);

        .lm_tab {
          background-color: var(--w-gl-level-2-header-bg-color);

          .lm_title {
            background-color: var(--w-gl-level-2-header-bg-color);

            color: var(--w-main-font-color);
            text-transform: none;
            @include wtr-font-roboto-black;
          }

          &.lm_active {
            .lm_title {
              color: var(--w-primary-color);
              border-bottom: 1px solid var(--w-primary-color);
            }
          }

          .lm_left {
            height: 70%;
          }
        }
      }

      .lm_tabs {
        .lm_tab {
          @include dl-border-radius-top;
        }
      }

      .lm_maximise,
      .lm_popout {
        @include wtr-size-rules($wtr-icon-xs-size);
      }
    }

    .lm_item {
      // border: 1px solid lightgray;
      @include dl-border-radius-full;

      .lm_content {
        @include dl-border-radius-full;
      }
    }

    .lm_column,
    .lm_row {
      box-shadow: none;
    }

    .lm_vertical,
    .lm_horizontal {
      border: 3px solid var(--w-component-bg-color);
    }
  }

  .widget_position {
    .grid-container {
      height: 100% !important;
    }

    .k-grid {
      border-radius: 0px 0px $wtr-main-space $wtr-main-space !important;
      .k-pager {
        border-radius: 0px 0px $wtr-main-space $wtr-main-space !important;
      }
    }
  }
}

.dl-level-3 {
  .lm_goldenlayout {
    .lm_header {
      height: var(--w-gl-level-3-header-height) !important;
      background-color: var(--w-gl-level-3-header-bg-color);

      .lm_tabs,
      .lm_tabdropdown_list {
        background-color: var(--w-gl-level-3-header-bg-color);

        .lm_tab {
          background-color: var(--w-gl-level-3-header-bg-color);

          .lm_title {
            background-color: var(--w-gl-level-3-header-bg-color);
            color: var(--w-main-font-color);
            font-family: Roboto, sans-serif;
            font-weight: 400;
          }

          &.lm_active {
            .lm_title {
              color: var(--w-primary-color);
              border-bottom: 1px solid var(--w-primary-color);
            }
          }
        }
      }
    }
  }
}

.dl-single-tab-overflow {
  // Subtracting the space for the maximize icon and the dropdown icon.
  width: calc(100% - 60px);
}
