@use '../basic/wtr-variables.scss' as *;

.configurable-form-row {
  display: flex;
  flex-wrap: wrap;
}

.mat-mdc-form-field-infix {
  .wlm-chips-input {
    margin-top: $wtr-main-space;
    margin-bottom: $wtr-main-space;
    margin-left: -$wtr-main-space;
    margin-right: -$wtr-main-space;
  }
}
