@use 'wlm-theme' as *;

.mat-checked {
  .mat-slide-toggle-bar {
    background-color: var(--w-primary-color-400) !important;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--w-primary-color) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: var(--w-switch-selected-icon-color) !important;
}

.mdc-switch {
  height: $wtr-main-space * 5;
}
