@use '../basic/wtr-variables.scss' as *;

@mixin nav-link-paddings {
  padding: 0px;
  padding-left: $wtr-main-space * 2;
  padding-right: $wtr-main-space;
}

.nav-link-expansion-panel {
  .mat-expansion-panel-header {
    @include nav-link-paddings;
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
    align-items: center;
    margin-right: 0px;
  }
}

.nav-module-link-container {
  height: $wtr-menu-link-height;
  background-color: var(--w-component-bg-color);
  @include nav-link-paddings;

  mat-icon {
    color: var(--w-nav-bar-icon-color) !important;
  }
}

.nav-link-container {
  @extend .nav-module-link-container;
  background-color: transparent; // var(--w-list-item-bg-color);
  display: flex;
  align-items: center;
  padding-left: $wtr-main-space * 6 !important;
}

.nav-link-text {
  margin-left: 1.2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  color: var(--w-nav-bar-font-color);
}

.nav-module-link-text {
  @extend .nav-link-text;
  font-weight: bold;
}

.nav-module-link-bold {
  font-weight: bold;
}

.nav-menu-link-list-panel {
  min-height: $wtr-menu-link-height !important;
}

.menu-link-indicator {
  height: $wtr-menu-link-height;
  width: 0.4375rem;
}

.menu-link-indicator-active {
  background-color: var(--w-primary-color);
}

.top-level-menu-link {
  .navigation-container:hover {
    background: none !important;
  }
}
