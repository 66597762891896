@use 'wlm-theme' as *;

$wtr-radio-size: 20px;
$wtr-radio-lg-size: 24px;

.mat-mdc-radio-button {
  > .mdc-form-field {
    > label {
      font-size: $wtr-input-font-size;
    }
  }
}

.radio-group-container {
  display: flex;
  align-items: center;

  input.k-radio {
    border-color: var(--w-primary-color);
    border-width: 1px;
    width: $wtr-radio-size;
    height: $wtr-radio-size;
    margin-right: divide($wtr-main-space, 2);
  }

  input.k-radio:before {
    width: 10px;
    height: 10px;
  }

  label {
    font-family: $wtr-radios-font-family !important;
    font-size: $wtr-main-font-size;
  }
}

mat-radio-button {
  .mat-radio-inner-circle {
    background: var(--w-primary-color) !important;
  }
}

mat-radio-button.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: var(--w-primary-color) !important;
  }
}

.radio-position {
  display: flex;
  flex-direction: row;
  gap: $wtr-main-space * 2;
  font-size: $wtr-input-font-size !important;
}

@mixin wtr-radio-size($size) {
  --mdc-radio-state-layer-size: #{$size};

  &.mdc-radio,
  .mdc-radio {
    width: $size;
    height: $size;

    .mdc-radio__background {
      width: $size;
      height: $size;
    }
  }
}

body {
  @include wtr-radio-size($wtr-radio-size);

  .wtr-radio-lg {
    @include wtr-radio-size($wtr-radio-lg-size);
  }
}
