@use '../basic/wtr-variables.scss' as *;
wlm-selection-list.selection-list-items-large {
  .mat-list-option {
    height: 70px;
  }

  .header {
    min-width: 325px;
  }

  .mat-list-base {
    min-width: 325px;
  }
}

mat-select.pagesize-selector {
  width: $wtr-main-space * 7;
  margin: 0 10px;
  border: 1px solid var(--w-main-border-color);
  border-radius: divide($wtr-main-space, 2);
  height: 20px;

  .mat-select-value {
    padding-left: divide($wtr-main-space, 2);
  }

  .mat-mdc-select-value {
    display: flex;
    justify-content: center;
  }

  .mat-mdc-select-arrow-wrapper {
    padding-right: $wtr-main-space;
  }
}

.autocomplete-options {
  mat-option {
    font-size: $wtr-input-font-size !important;
    height: $wtr-input-list-item-height !important;
  }
}

.drag-list-container > .cdk-virtual-scroll-viewport > .cdk-virtual-scroll-content-wrapper {
  width: inherit !important;
}
