@use 'wlm-theme' as *;

.mat-step-icon {
  background-color: var(--w-primary-color) !important;
  color: var(--w-primary-color-contrast-font-color) !important;
}

.mat-horizontal-content-container {
  padding-top: $wtr-main-space !important;
}

.mat-step {
  background-color: var(--w-component-bg-color);
}

.mat-step-label {
  white-space: normal !important;
}
