@use 'wlm-theme' as *;

.mat-mdc-menu-item {
  // This was default to 1 and made some letters clip due to not enough line height.
  --mdc-typography-body1-line-height: 1.2;

  .mat-icon {
    margin-right: 0px !important;
    & > svg {
      height: inherit;
      width: inherit;
    }
  }

  .mdc-list-item__primary-text {
    & > span {
      padding-left: $wtr-main-space;
    }
  }
}

.account-card {
  .mat-mdc-menu-item {
    &.mat-mdc-menu-trigger {
      padding-right: $wtr-main-space * 2;

      .mat-mdc-menu-submenu-icon {
        display: none;
      }
    }

    &.menu-option-slider {
      padding-right: $wtr-main-space;
    }
  }
}

.lang-option {
  .mdc-list-item__primary-text {
    justify-content: normal !important;
  }
}

//top-bar
.top-bar {
  border-bottom-width: thin;
  border-bottom-color: var(--w-component-bg-color);
  border-style: hidden hidden solid hidden;
}
