@use '../basic/wtr-variables.scss' as *;

.white-background-input-field {
  div.mat-form-field-outline {
    background: var(--w-component-bg-color);
    opacity: 0.8;
    border-radius: 5px;
  }
}

.input-autocomplete-list {
  font-size: $wtr-input-font-size !important;
  line-height: $wtr-input-list-item-height !important;
  height: $wtr-input-list-item-height !important;
}
