@use 'sass:math';

/*
 * Contains variables mainly for sizes and typography.
 * Ideally, theming the wtr-theme would consist on replacing the variables declared here.
 */

/* Font Sizes */

$wtr-main-font-size: 14px;
$wtr-headline-font-size: 32px;
$wtr-title-font-size: 24px;
$wtr-subheading-font-size: 16px;
$wtr-input-font-size: 12px;
$wtr-button-font-size: 12px;
$wtr-tabs-font-size: 12px;
$wtr-tooltip-font-size: 13px;
$wtr-grid-filter-button-font-size: 12px;
$wtr-grid-filter-button-width: 91px;
$wtr-grid-filter-button-height: 22px;
$wtr-checkbox-size: 22px;
$wtr-checkbox-size-lg: 24px;
$wtr-trb-container-height: 35px;
$wtr-filter-input-font-size: 12px;
$wtr-input-list-item-height: 36px;
$wtr-map-control-font-size: 12px;

$wtr-card-label-margin: 6px;

$wtr-functions-controls-height: 40px;
$wtr-font-weight-100: 100;
$wtr-font-weight-500: 500;

/* Font Families */

@function wtr-font-family($font) {
  @return $font, Roboto, sans-serif;
}

$wtr-main-font-family: wtr-font-family('Roboto Regular');
// Theme-TODO: in invision, these are defined as Bold in one place and Regular in other.
$wtr-headline-font-family: wtr-font-family('Roboto Regular');
$wtr-title-font-family: wtr-font-family('Roboto Regular');
$wtr-subheading-font-family: wtr-font-family('Roboto Bold');
$wtr-button-font-family: wtr-font-family('Roboto Black');
$wtr-tabs-font-family: wtr-font-family('Roboto Medium');
$wtr-grid-font-family: wtr-font-family('Roboto Regular');
$wtr-grid-header-font-family: wtr-font-family('Roboto Medium');
$wtr-grid-filter-button-font-family: wtr-font-family('Roboto Black');
$wtr-grid-filter-input-font-family: wtr-font-family('Roboto Regular');
$wtr-radios-font-family: wtr-font-family('Roboto Regular');
$wtr-action-button-font-family: wtr-font-family('Roboto Regular');
$wtr-generic-card-title-font-family: wtr-font-family('Roboto Bold');
$wtr-generic-card-subtitle-font-family: wtr-font-family('Roboto Medium');
$wtr-generic-card-secondary-font-family: wtr-font-family('Roboto Regular');

@mixin wtr-font-roboto-black {
  font-family: wtr-font-family('Roboto Bold');
}

/* Font Misc */

$wtr-main-letter-spacing: 0;
$wtr-main-line-height: 1; // Unitless -> The same as the font size.

/* Borders/Padding/Margin spacing */
$wtr-main-space: 8px;
$wtr-icon-md-size: 24px;
$wtr-icon-sm-size: 22px;
$wtr-icon-xs-size: 18px;
$wtr-icon-lg-size: 30px;
$wtr-icon-toast-size: 40px;
$wtr-icon-swal-size: 60px;
$wtr-fields-border-radius: 4px;

$wtr-space-1: 1px;
$wtr-space-2: 2px;
$wtr-space-4: 4px;
$wtr-space-10: 10px;
$wtr-space-30: 30px;

/* Width/Height Misc*/
$wtr-width-330: 330px;
$wtr-menu-link-height: 48px;
$wtr-menu-item-min-height: 48px;

/*Map variables*/
$wtr-map-filter-width: 338px;

/** Helper functions */

@function divide($a, $b) {
  @return math.div($a, $b);
}

@mixin wtr-box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin wtr-icon-mask($url) {
  -webkit-mask: $url no-repeat center;
  mask: $url no-repeat center;
}

$column-secondary-font-size: 12px;
$column-main-font-size: 14px;
$wtr-generic-card-title-font-size: 12px;
$wtr-generic-card-subtitle-font-size: 10px;
$wtr-generic-card-secondary-font-size: 12px;

$wtr-treeview-item-height: 70px;
$wtr-form-field-height: 40px;
$wtr-map-filter-item-height: 48px;
$wtr-form-field-sm-height: 28px;
$wtr-search-field-height: $wtr-main-space * 4;
