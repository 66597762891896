@use 'wlm-theme' as *;

formly-form {
  .mat-mdc-text-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.mdc-text-field--outlined {
      padding-left: $wtr-main-space * 2;
    }
  }

  .mat-mdc-floating-label,
  .mdc-floating-label {
    mat-label {
      font-size: $wtr-input-font-size !important;
    }
  }

  formly-field-mat-select,
  formly-field-mat-input,
  .custom-formly-field {
    width: 100%;

    .mat-mdc-select-trigger {
      justify-content: space-between;
    }
  }

  .mat-mdc-select-arrow {
    margin-right: 6px; // So it aligns with time pickers, which clock icons are more difficult to align.
    svg {
      left: 100%;
    }
  }
}

formly-form.dynamic-form-theme {
  .mat-mdc-text-field-wrapper {
    height: $wtr-main-space * 4 !important;
  }

  .mat-mdc-floating-label,
  .mdc-floating-label {
    padding-bottom: 12px !important;
  }
}

.formly-field-no-border {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-width: 0px !important;
  }
}

.formly-field-extra-padding {
  padding-bottom: $wtr-main-space * 2 !important;
}
