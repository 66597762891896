@use './../wlm-theme.scss' as *;
@use '@angular/material';

$font-size: $wlm-font-size;
$font-family: $wlm-font-family;

$navigator-button-color: #beccd6;

$theme-type: light;
$base-theme: Material;
$skin-name: WLM-Material-Theme;
$swatch-name: Material;

$border-radius: 2px;
$drawer-item-padding-y: 0;
$drawer-item-padding-x: 0;
$treeview-item-padding-y: 1px;
$treeview-item-selected-bg: #d8d8d8;
$treeview-item-hovered-bg: none;
$treeview-item-border-radius: 15px;
$button-border-width: 1px;

$header-font-size: 13px;
$header-line-height: 24px;

$pager-border-color: #d8d8d8;

$column-main-font-size: 14px;

wlm-hierarchy-tree-filter .k-treeview .k-in {
  margin: 1px;
}

wlm-hierarchy-tree-filter .mat-standard-chip {
  min-height: 29px;
}

// Kendo Grid Style
kendo-grid {
  width: 100%;
}
kendo-grid-column {
  height: 100% !important;
}

kendo-grid .k-checkbox {
  margin-bottom: 1px !important;
}

.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
  right: 6px !important;
}

.k-grid-header .k-filterable {
  padding-right: calc(calc(1.4285714286em + 16px + 2px) + 14px) !important;
}

a.k-grid-filter {
  top: 4px;
}
.k-grid tbody tr {
  line-height: 35px !important;
}

.k-chart-tooltip-wrapper .k-popup {
  opacity: 0.8 !important;
}

.k-grid table td.k-state-focused,
.k-grid table th.k-state-focused {
  background: none !important;
}

.k-button,
.k-list .k-item,
.k-list-optionlabel,
.k-timeselector {
  font-family: $wlm-font-family !important;
}

.k-list .k-item {
  font-size: $wlm-font-size !important;
}

.k-grid-header {
  font-size: $wlm-subtitle-font-size !important;
}

.k-grid-header .k-header > .k-link {
  margin-right: 1px !important;
  padding-right: 2px !important;
}

.k-grid th {
  padding: 10px 6px !important;
}

.k-grid td:nth-child(n + 1) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

wlm-event-chart .k-scheduler-timeline-month-view .k-scheduler-table tr:nth-of-type(2) {
  display: none !important;
}

wlm-event-chart .k-scheduler-timeline-week-view .k-scheduler-table tr:nth-of-type(2) {
  display: none !important;
}

kendo-stockchart .k-handle {
  background-color: $navigator-button-color !important;
}

.k-grid .no-padding {
  padding: 0 !important;
}
.k-grid tbody tr td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

wlm-generic-grid,
wlm-local-grid {
  .k-grid td {
    padding: 0px !important;
  }

  .k-grid td > span {
    padding: 0px 8px !important;
  }

  .k-numerictextbox {
    font-size: $wlm-font-size !important;
  }

  .k-pager-input .k-numerictextbox {
    width: 4em;
  }
}

.k-pager-numbers .k-link {
  width: 30px;
}

//grid columns/cells tooltip
.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-grid .k-grid-content td:first-child {
  padding-left: $wtr-main-space !important;
}

.k-grid tr.k-alt {
  background: w-color-alpha(var(--w-primary-color), 0.04);
  /* #23097   
  background: linear-gradient(
    0deg,
    w-color-alpha(var(--w-primary-color), 0.05) 0%,
    w-color-alpha(var(--w-primary-color), 0.03) 100%
  );
  */
}

.k-grid-header .k-grid-filter.k-state-active,
.k-grid-header .k-header-column-menu.k-state-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-state-active {
  background: w-color-alpha(var(--w-primary-color), 0.9) !important;
}

.k-grid-header .k-grid-filter.k-state-border-down,
.k-grid-header .k-grid-filter:focus,
.k-grid-header .k-header-column-menu.k-state-border-down,
.k-grid-header .k-header-column-menu:focus,
.k-grid-header .k-hierarchy-cell .k-icon.k-state-border-down,
.k-grid-header .k-hierarchy-cell .k-icon:focus {
  background: w-color-alpha(var(--w-grid-header-accent-color), 0.3) !important;
}

kendo-scheduler .k-event,
.k-event {
  padding-right: 0px !important;
}

// Uncomment to use water-drop gif in the grids

.k-loading-mask .k-loading-image {
  color: var(--w-primary-color);
}

.k-scheduler-fullday {
  display: none;
}

wlm-profile-dialogs-hierarchy-grid .k-grid-header .k-header {
  text-align: center;
}

wlm-notification-configuration-popup .k-grid-header .k-header {
  text-align: center;
}

.mat-expansion-panel-header-title {
  margin-right: 10px !important;
}

.custom-k-page-sizes {
  .k-input-value-text {
    width: auto !important;
  }
}

.custom-k-multiselect-scroll {
  .k-multiselect-wrap {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.k-drawer-item {
  padding: 0px !important;
}

.custom-k-multiselect-custom-hide {
  .k-multiselect {
    .k-custom-item {
      display: none !important;
    }
  }
}

.k-list .k-item:hover.k-state-selected,
.k-list .k-item.k-state-hover.k-state-selected,
.k-list-optionlabel:hover.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected {
  color: var(--w-primary-color) !important;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  color: var(--w-primary-color) !important;
}

.k-button-primary,
.k-button.k-primary,
.k-button.k-button-solid-primary {
  border-color: var(--w-primary-color);
  color: var(--w-primary-color-contrast-font-color) !important;
  background-color: var(--w-primary-color);
}

.k-button.k-button-solid-primary:disabled {
  color: var(--w-primary-color-disabled);
  background-color: #e0e0e0;
  box-shadow: none;
  border: 0;
}

.k-checkbox:checked,
.k-checkbox.k-checked,
.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate,
.k-checkbox.k-indeterminate {
  border-color: var(--w-primary-color);
  color: var(--w-primary-color-contrast-font-color) !important;
  background-color: var(--w-primary-color);
  border-top-width: 0px;
}

.k-grid {
  .k-column-title {
    font-size: $wlm-font-size !important;
  }

  .k-grid-content {
    .column-cell {
      font-size: $wlm-font-size !important;
    }
  }
}

.k-textbox:focus-within,
.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textbox.k-state-focused {
  border-top: 0px;
  border-color: var(--w-primary-color) !important;
}

.k-textbox::selection,
.k-textbox .k-input-value-text::selection {
  background-color: var(--w-primary-color) !important;
}

.k-grid .k-grid-content .column-cell {
  font-size: $wlm-font-size !important;
}

.k-grid-filter,
.k-grid-filter-menu.k-grid-header-menu {
  padding: 6px !important;
  width: calc(1.4285714286em + 8px) !important;
  height: calc(1.4285714286em + 8px) !important;
}

kendo-grid-filter-menu:hover {
  background: rgb(0 0 0 / 15%);
  border-radius: 50%;
}

.k-filter-menu,
.k-grid-filter-popup {
  &.k-popup {
    background-color: var(--w-grid-filter-popup-bg-color);

    .k-filter-menu-container {
      width: auto;
      padding: 0px;

      .k-multiselect,
      .k-dropdowntree {
        .k-multiselect-wrap {
          .k-button {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

div.k-filter-menu-container {
  display: block;
  padding: 0;
}

.k-grid-pager {
  padding: 0;
}

.selector-grid {
  margin: 8px;

  .k-grid {
    border-color: var(--w-grid-border-color);
    border-radius: 8px 8px 0 0;
  }

  .k-grid .k-grid-aria-root {
    border-radius: 8px 8px 0 0;
  }

  .k-grid-header {
    font-size: $header-font-size !important;
    font-weight: normal;
    line-height: $header-line-height;
    color: var(--w-grid-header-font-color);
    background-color: var(--w-grid-header-bg-color);
    border-bottom: 0px;
  }

  /*
  .k-grid tr.k-alt {
    background: white;
  }
  */
  .k-pager-nav {
    border-radius: 0px;
    min-width: 20px !important;
  }

  kendo-pager-next-buttons {
    .k-pager-nav {
      width: 28px;
      border-left: 1px;
      border-color: var(--w-grid-border-color) !important;
      border-style: solid;
    }

    .k-pager-last {
      width: 36px;
    }
  }

  kendo-pager-prev-buttons {
    .k-pager-nav {
      width: 28px;
      border-right: 1px;
      border-color: var(--w-grid-border-color) !important;
      border-style: solid;
    }

    .k-pager-first {
      width: 36px;
    }
  }

  .k-grid-filter .k-icon::before,
  .k-i-filter::before {
    content: url('/assets/images/grid/filter-list-black-24dp.svg') !important;
  }

  .k-i-arrow-60-right::before,
  .k-i-arrow-e::before,
  .k-i-sarrow-e::before,
  .k-i-expand::before,
  .k-i-expand-e::before,
  .k-i-caret-alt-right {
    content: url('/assets/images/grid/pager/ic-right-arrow.svg') !important;
  }

  .k-i-arrow-60-left::before,
  .k-i-arrow-w::before,
  .k-i-sarrow-w::before,
  .k-i-expand-w::before,
  .k-i-caret-alt-left {
    content: url('/assets/images/grid/pager/ic-left-arrow.svg') !important;
  }

  .k-i-arrow-end-right::before,
  .k-i-seek-e::before,
  .k-i-caret-alt-to-right {
    content: url('/assets/images/grid/pager/ic-right-arrow2.svg') !important;
  }

  .k-i-arrow-end-left::before,
  .k-i-seek-w::before,
  .k-i-caret-alt-to-left {
    content: url('/assets/images/grid/pager/ic-left-arrow2.svg') !important;
  }
}

.k-pager-sizes {
  .k-dropdownlist {
    height: 100%;
    font-size: $font-size !important;

    .k-input-value-text-inner {
      justify-content: flex-end;
    }

    .k-input-value-text {
      margin-right: -$wtr-main-space;
    }
  }
}

kendo-pager-prev-buttons {
  padding-left: 8px;
  padding-right: 0px;
}

kendo-pager-input {
  padding-right: 8px;
}

wlm-widget-selector {
  .k-item.k-treeview-item[aria-level='2'] {
    padding-left: 0px;
  }

  .k-state-focused,
  .k-in {
    background-color: transparent !important;
  }
}

.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked,
.k-grid .k-grid-header-locked .k-header,
.k-grid .k-grid-content-locked td {
  border-color: rgba(0, 0, 0, 0.12);
}

.k-grid-content-locked {
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 0 2px 0 0;
  box-sizing: content-box;
}

.k-input {
  background-color: transparent;
}

col.k-sorted,
.k-table-th.k-sorted {
  background-color: transparent;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 25rem !important;
}

kendo-grid-list,
.selector-grid {
  .k-grid-pager {
    .k-pager-nav {
      border-top: 0px !important;
      border-left: 1px solid var(--w-grid-border-color) !important;
      border-right: 0px !important;

      &[icon='caret-alt-left'],
      &[icon='caret-alt-to-right'] {
        border-right: 1px solid var(--w-grid-border-color) !important;
      }
    }
  }
}
