@use '../basic/wtr-variables.scss' as *;

*::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

*::-webkit-scrollbar-thumb:hover {
  border: none !important;
}

*::-webkit-scrollbar-thumb {
  border: 2px solid transparent !important;
  border-radius: 20px !important;
  background-color: var(--w-scrollbar-color) !important;
  background-clip: content-box !important;
}

*::-webkit-scrollbar-track {
  background: var(--w-scrollbar-bg-color) !important;
}
