@use '../basic/wtr-variables.scss' as *;

.base-pill {
  font-size: $wtr-input-font-size;
  display: flex;
  padding: 5px;
  line-height: 20px;
  border-radius: 5px;
  justify-content: center;
  max-width: 80px;
}

.primary-pill-color,
.w-primary-pill-color {
  color: var(--w-pill-primary-font-color);
  background-color: var(--w-pill-primary-bg-color);
}

.primary-pill,
.w-primary-pill {
  @extend .base-pill;
  @extend .primary-pill-color;
}

.red-pill-color {
  color: var(--w-pill-red-font-color);
  background-color: var(--w-pill-red-bg-color);
}

.red-pill {
  @extend .base-pill;
  @extend .red-pill-color;
}

.white-pill-color {
  color: var(--w-pill-white-font-color);
  background-color: var(--w-pill-white-bg-color);
  border: 1px solid var(--w-pill-white-font-color);
}

.white-pill {
  @extend .base-pill;
  @extend .white-pill-color;
}

.green-pill-color {
  color: var(--w-pill-green-font-color);
  background-color: var(--w-pill-green-bg-color);
}

.green-pill {
  @extend .base-pill;
  @extend .green-pill-color;
}

.yellow-pill-color {
  color: var(--w-pill-yellow-font-color);
  background-color: var(--w-pill-yellow-bg-color);
}

.yellow-pill {
  @extend .base-pill;
  @extend .yellow-pill-color;
}

//selected styles for combo
.w-primary-pill-selected {
  span.mat-mdc-select-min-line {
    @extend .w-primary-pill;
    padding: 2px;
  }
}

.red-pill-selected {
  span.mat-mdc-select-min-line {
    @extend .red-pill;
    padding: 2px;
  }
}

.white-pill-selected {
  span.mat-mdc-select-min-line {
    @extend .white-pill;
    padding: 2px;
  }
}

.green-pill-selected {
  span.mat-mdc-select-min-line {
    @extend .green-pill;
    padding: 2px;
  }
}

.yellow-pill-selected {
  span.mat-mdc-select-min-line {
    @extend .yellow-pill;
    padding: 2px;
  }
}

.selected-padding {
  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    padding: 8px 0 8px 0 !important;
  }

  .mat-select-arrow {
    margin-top: 10px !important;
  }
}

.campaign-status-empty {
  min-height: 25px !important;

  .mat-select-arrow {
    margin-top: 15px !important;
  }
}
