@use '../functions/w-functions.scss' as water;

.w-theme-dark {
  @include water.define-w-color(--w-primary-color, #1fb0c1);
  @include water.define-w-color(--w-primary-color-a700, #63e4ff);
  @include water.define-w-color(--w-primary-color-a400, #7de8ff);
  @include water.define-w-color(--w-primary-color-a200, #b0f1ff);
  @include water.define-w-color(--w-primary-color-a100, #e3faff);
  @include water.define-w-color(--w-primary-color-900, #0a9cb7);
  @include water.define-w-color(--w-primary-color-800, #12aac2);
  @include water.define-w-color(--w-primary-color-700, #17b2c8);
  @include water.define-w-color(--w-primary-color-600, #1bbace);
  @include water.define-w-color(--w-primary-color-500, #1fc0d3);
  @include water.define-w-color(--w-primary-color-400, #32b3c7);
  @include water.define-w-color(--w-primary-color-300, #279aab);
  @include water.define-w-color(--w-primary-color-200, #218595);
  @include water.define-w-color(--w-primary-color-100, #1a626d);
  @include water.define-w-color(--w-primary-color-50, #12454d);

  --w-private-neutral-color-black: #192122;
  --w-private-neutral-color-gray-light: #d8e1e3;
  @include water.define-w-color(--w-private-neutral-color-gray, #a6b0b4);
  --w-private-neutral-color-gray-2: #c5d0d4;
  --w-private-neutral-color-white: #fff;
  --w-private-neutral-color-white-light-2: #ccc;

  // Color fonts

  @include water.define-w-color(--w-main-font-color, #f2f2f2);
  // Font for using when primary color is in bg.
  --w-primary-color-contrast-font-color: var(--w-private-neutral-color-gray-light);
  --w-error-contrast-font-color: var(--w-private-neutral-color-gray-light);

  --w-private-light-font: var(--w-private-neutral-color-gray-light);
  --w-private-dark-font: var(--w-private-neutral-color-black);

  --w-primary-color-contrast-50: var(--w-private-dark-font);
  --w-primary-color-contrast-100: var(--w-private-dark-font);
  --w-primary-color-contrast-200: var(--w-private-dark-font);
  --w-primary-color-contrast-300: var(--w-private-dark-font);
  --w-primary-color-contrast-400: var(--w-private-light-font);
  --w-primary-color-contrast-500: var(--w-private-light-font);
  --w-primary-color-contrast-600: var(--w-private-light-font);
  --w-primary-color-contrast-700: var(--w-private-light-font);
  --w-primary-color-contrast-800: var(--w-private-light-font);
  --w-primary-color-contrast-900: var(--w-private-light-font);
  --w-primary-color-contrast-a100: var(--w-private-dark-font);
  --w-primary-color-contrast-a200: var(--w-private-dark-font);
  --w-primary-color-contrast-a400: var(--w-private-dark-font);
  --w-primary-color-contrast-a700: var(--w-private-dark-font);

  // Main colors
  --w-main-bg-color: #1d1d1f;
  @include water.define-w-color(--w-component-bg-color, #3d3c3f);

  --w-main-border-color: var(--w-private-neutral-color-gray-2);
  --w-popup-bg-color: var(--w-main-bg-color);
  @include water.define-w-color(--w-error-color, #cc2e29);
  --w-warning-color: #d9950b;
  --w-success-color: #19811a;
  --w-list-item-bg-color: #{water.w-color-alpha(var(--w-primary-color-300), 0.05)};
  --w-app-title-font-color: var(--w-main-font-color);
  --w-page-title-font-color: var(--w-primary-color);
  --w-cart-bg-color: var(--w-component-bg-color);
  --w-default-icon-color: var(--w-main-font-color);

  // Buttons
  @include water.define-w-color(--w-button-font-color, #1fb0c1);
  --w-button-primary-bg-color: var(--w-private-neutral-color-white);
  --w-button-regular-font-color: var(--w-primary-color);
  --w-button-regular-bg-color: var(--w-private-neutral-color-white);
  --w-button-toggle-bg-color: #424242;
  --w-button-toggle-color: #ffffff;
  --w-form-inner-title-color: var(--w-main-font-color);
  --w-nav-button-disabled: var(--w-primary-color-300);
  --w-button-outline-border: var(--w-private-neutral-color-gray-light);

  --w-disabled-color-alpha: 0.38;
  --w-primary-color-disabled: #{water.w-color-alpha(
      var(--w-primary-color),
      var(--w-disabled-color-alpha)
    )};
  --w-button-font-color-disabled: #{water.w-color-alpha(
      var(--w-button-font-color),
      var(--w-disabled-color-alpha)
    )};
  --w-error-color-disabled: #{water.w-color-alpha(
      var(--w-error-color),
      var(--w-disabled-color-alpha)
    )};

  --w-headline-font-color: var(--w-primary-color);
  --w-title-font-color: var(--w-primary-color);
  --w-subheading-font-color: var(--w-primary-color);
  --w-filter-item-header-color: var(--w-primary-color);
  --w-filter-field-label-color: var(--w-primary-color);

  @include water.define-w-color(--w-nav-bar-font-color, #e3e3e3);
  @include water.define-w-color(--w-nav-bar-link-color, #d8e1e3);
  --w-nav-bar-icon-color: var(--w-main-font-color);
  --w-nav-bar-icon-disabled-color: #{water.w-color-alpha(var(--w-main-font-color), 0.3)};
  --w-nav-bar-menu-hover-color: var(--w-primary-color);

  // Scrollbars
  --w-scrollbar-color: var(--w-main-bg-color);
  --w-scrollbar-bg-color: var(--w-component-bg-color);

  // Golden Layout
  --w-gl-tab-header-bg-color: var(--w-primary-color-300);
  --w-gl-tab-header-border-color: var(--w-primary-color-400);
  --w-gl-tab-header-font-color: var(--w-primary-color);
  --w-gl-tab-header-button-color: var(--w-private-neutral-color-gray);
  --w-gl-tab-header-button-color-active: var(--w-private-neutral-color-gray);
  --w-gl-subtitle-header-font-color: var(--w-main-font-color);
  --w-gl-icon-color: var(--w-primary-color);
  --w-gl-drag-icon-color: #545c69;
  --w-gl-drag-icon-color-unfocused: var(--w-private-neutral-color-gray-2);
  --w-gl-level-1-header-bg-color: var(--w-component-bg-color);
  --w-gl-level-2-header-bg-color: var(--w-primary-color-50);
  --w-gl-level-2-header-shadow-color: rgba(0, 0, 0, 0.37);
  --w-gl-level-3-header-bg-color: var(--w-gl-level-2-header-bg-color);
  --w-gl-item-border-color: #636366;

  // Grid
  //--> row colors
  @include water.define-w-color(--w-private-alt-row-color, #565458);

  --w-grid-alt-bg-color: var(--w-private-alt-row-color);
  --w-grid-hover-color: var(--w-primary-color-100);
  --w-grid-header-font-color: var(--w-primary-color);
  --w-grid-header-bg-color: var(--w-component-bg-color);
  --w-grid-icon-color: var(--w-private-neutral-color-gray);
  --w-grid-header-drag-icon-color: var(--w-private-neutral-color-gray-light);
  --w-grid-header-drag-icon-color-hover: var(--w-private-neutral-color-gray);
  --w-grid-selector-column-main-font-color: var(--w-main-font-color);
  --w-grid-selector-column-secondary-font-color: var(--w-main-font-color);
  @include water.define-w-color(--w-grid-header-accent-color, #1e5e67);
  --w-grid-footer-bg-color: var(--w-component-bg-color);
  --w-grid-pdf-export-font-color: #888;
  --w-grid-filter-popup-bg-color: var(--w-component-bg-color);
  --w-grid-border-color: #d8d8d8;
  --w-grid-header-filter-icon-color: var(--w-private-neutral-color-gray);
  --w-grid-header-filter-filtered-icon-color: var(--w-primary-color);
  --w-grid-pager-font-color: var(--w-private-neutral-color-gray);
  --w-grid-pager-font-disabled-color: #{water.w-color-alpha(
      var(--w-private-neutral-color-gray),
      var(--w-disabled-color-alpha)
    )};
  --w-grid-row-clicked-bg-color: var(--w-primary-color-700);

  // Right Panel
  --w-rp-title-color: var(--w-primary-color);
  --w-rp-bg-color: var(--w-component-bg-color);
  --w-rp-handle-bg-color: var(--w-primary-color);
  --w-rp-handle-border-color: var(--w-primary-color-300);

  --w-separator-color: #beccd6;
  --w-separator-color-secondary: #beccd660;

  --w-comment-header-color: var(--w-main-font-color);

  --w-alert-critical-bg-color: #cc0000;
  --w-alert-critical-font-color: var(--w-main-font-color);
  --w-alert-high-bg-color: #e67300;
  --w-alert-high-font-color: var(--w-private-neutral-color-black);
  --w-alert-medium-bg-color: #ffcc00;
  --w-alert-medium-font-color: var(--w-private-neutral-color-black);
  --w-alert-low-bg-color: #99cc00;
  --w-alert-low-font-color: var(--w-private-neutral-color-black);

  // Kendo Tree
  --w-tree-leaf-selected-color: var(--w-primary-color);
  --w-tree-leaf-selected-bg-color: #37373b;
  --w-selector-tree-leaf-bg-color: var(--w-component-bg-color);
  --w-selector-tree-leaf-hover-bg-color: var(--w-component-bg-color);
  --w-selector-tree-leaf-selected-bg-color: var(--w-tree-leaf-selected-bg-color);
  --w-selector-tree-leaf-selected-color: var(--w-primary-color);

  // Drag & drop List
  --w-drag-list-container-border-color: var(--w-primary-color);
  --w-drag-list-container-bg-color: var(--w-main-bg-color);
  --w-drag-list-selected-contrast-color: var(--w-main-font-color);
  --w-drag-list-item-bg-color: var(--w-main-bg-color);
  --w-drag-list-border-color: var(--w-primary-color);
  --w-drag-list-bg-color: var(--w-component-bg-color);
  --w-drag-list-selected-bg-color: var(--w-primary-color-400);
  --w-drag-scrollbar-bg-color: var(--w-main-bg-color);
  --w-drag-scrollbar-thumb-bg-color: var(--w-component-bg-color);
  --w-drag-list-inner-margin-color: var(--w-primary-color-800);

  // Map
  --w-map-filter-item-color: #334042;
  --w-map-filter-button-color: #fa6400;
  --w-map-filter-tab-label-font-color: var(--w-main-font-color);
  --w-map-filter-tab-label-bg-color: var(--w-component-bg-color);
  --w-map-filter-tab-label-active-font-color: var(--w-main-font-color);
  --w-map-filter-tab-label-active-bg-color: var(--w-primary-color);
  --w-map-drag-icon-color: var(--w-private-neutral-color-gray-light);
  --w-map-time-bar-bg-color: rgba(0, 0, 0, 0.4);
  --w-map-time-bar-slider-color: #4cda22;
  --w-map-time-bar-pointer-color: white;
  --w-map-time-bar-font-color: var(--w-private-neutral-color-white);
  --w-map-layer-item-color: var(--w-private-neutral-color-gray-light);

  --w-menu-item-selected-color: #d8e1e3;

  --w-input-disabled-bg-color: var(--w-component-bg-color);
  --w-checkbox-border-color: var(--w-main-font-color);

  --w-no-data-message-color: var(--w-main-font-color);

  --w-value-list-label-color: var(--w-primary-color);

  // Pills
  --w-pill-primary-font-color: var(--w-private-neutral-color-white);
  --w-pill-primary-bg-color: var(--w-primary-color);
  --w-pill-red-font-color: var(--w-private-neutral-color-white);
  --w-pill-red-bg-color: var(--w-error-color);
  --w-pill-white-font-color: var(--w-private-neutral-color-black);
  --w-pill-white-bg-color: var(--w-private-neutral-color-white);
  --w-pill-green-font-color: var(--w-private-neutral-color-white);
  --w-pill-green-bg-color: var(--w-success-color);
  --w-pill-yellow-font-color: var(--w-private-neutral-color-white);
  --w-pill-yellow-bg-color: var(--w-warning-color);

  --w-default-component-box-shadow-color: var(--w-private-neutral-color-white-light-2);

  // Alarms
  --w-alarm-counter-border-color: var(--w-private-neutral-color-black);
  --w-alarm-counter-base-color: var(--w-private-neutral-color-black);
  --w-alarm-counter-light-color: var(--w-private-neutral-color-white-light);
  --w-alarm-selected-bg-color: lightgray;

  // Spinners
  --w-spinner-overlay-font-color: #fff;
  --w-spinner-overlay-main-section-bg-color: #3dcd58;
  --w-spinner-overlay-secondary-section-bg-color: #3dcd5756;

  // Generic Card
  --w-generic-card-title1-font-color: var(--w-main-font-color);
  --w-generic-card-subtitle-font-color: var(--w-main-font-color);

  // Mat Menu
  --w-menu-option-selected-bg-color: rgb(190, 190, 190);

  // Spinner
  --w-spinner-bg-color: #{water.w-color-alpha(var(--w-component-bg-color), 0.5)};
  --w-spinner-bg-color-opaque: var(--w-component-bg-color);

  // Form Fields
  --w-form-field-outline-color: #{water.w-color-alpha(var(--w-main-font-color), 0.6)};
  --w-form-field-outline-hover-color: var(--w-primary-color);
  --w-form-field-outline-disabled-color: #{water.w-color-alpha(var(--w-main-font-color), 0.3)};
  --w-form-field-label-font-color: var(--w-main-font-color);

  // Swal
  --w-swal-font-color: var(--w-main-font-color);
  --w-swal-bg-color: var(--w-component-bg-color);
  --w-swal-bg-success-color: #5ea75f;
  --w-swal-success-icon-color: #e3f0e4;
  --w-swal-bg-error-color: #d58989;
  --w-swal-error-icon-color: #f5e3e3;
  --w-swal-bg-warning-color: #dfa530;
  --w-swal-warning-icon-color: #f4dfb6;
  --w-swal-bg-info-color: #4080bd;
  --w-swal-info-icon-color: #e4edf6;

  // Filters
  --w-filter-item-option-icon-color: var(--w-primary-color);

  // Calendar
  --w-calendar-button-bg-color: var(--w-main-bg-color);

  // Other
  --w-historical-form-container-border-color: #d3d3d3;
  --w-switch-selected-icon-color: var(--w-primary-color-contrast-font-color);

  // MDC Variables
  --mdc-checkbox-selected-checkmark-color: var(--w-main-font-color);

  //Shadows (box-shadow)
  --w-shadow-high: 0px 4px 4px 0px rgba(204, 204, 204, 1);
  --w-shadow-medium: 0px 1px 4px 0px rgba(204, 204, 204, 1);
  --w-shadow-low: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  --w-shadow-gl-item: 0px 1px 1px 0px var(--w-gl-item-border-color),
    0px -1px 1px 0px var(--w-gl-item-border-color), 1px 0px 1px 0px var(--w-gl-item-border-color),
    -1px 0px 1px 0px var(--w-gl-item-border-color);
}
