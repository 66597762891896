@use './../basic/wtr-variables.scss' as *;

.map-filters-tabs {
  .mat-mdc-tab-labels {
    padding: $wtr-space-2 0 0 $wtr-space-2;
  }
  .mat-mdc-tab.mdc-tab {
    height: $wtr-space-30 !important;
    color: var(--w-map-filter-tab-label-font-color);
    background: var(--w-map-filter-tab-label-bg-color);
    padding-left: $wtr-space-10;
    padding-right: $wtr-space-10;
    font-family: $wtr-headline-font-family !important;
    font-weight: $wtr-font-weight-500;
    font-size: $wtr-input-font-size;
    margin-right: $wtr-space-2;
    padding-bottom: $wtr-space-4;
    border: $wtr-space-1 solid var(--w-default-component-box-shadow-color);
    width: calc(50% - $wtr-space-2);
    opacity: unset;
  }

  .mdc-tab-indicator--active {
    background: var(--w-map-filter-tab-label-active-bg-color) !important;

    .mdc-tab__text-label {
      color: var(--w-map-filter-tab-label-active-font-color) !important;
    }
  }
}
