.ql-mention-list-container {
  color: var(--w-main-font-color) !important;

  .ql-mention-list {
    background-color: var(--w-component-bg-color) !important;

    .ql-mention-list-item {
      background-color: var(--w-component-bg-color) !important;

      &.selected {
        color: var(--w-primary-color) !important;
      }
    }
  }
}

.mention {
  background-color: var(--w-primary-color);
  color: var(--w-primary-color-contrast-font-color);
}

.ql-editor.ql-blank::before {
  color: var(--w-main-font-color);
}
